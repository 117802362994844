import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { addUser } from "../../redux/reducers/user";

const StudentRegistration = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const studentRegistration = async (e) => {
    e.preventDefault();
    try {
      if (name && email && phone && password) {
        setLoading(true);
        const data = {
          role: "STUDENT",
          fullName: name,
          emailAddress: email,
          phoneNumber: phone,
          password,
        };
        await dispatch(addUser(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.href = "/login";
            setLoading(false);
            return;
          }
        });
      } else {
        toast.error("All fields are required");
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="row no-gutters">
      <div className="col-xl-12 tab-content">
        <div
          id="sign-up"
          className="auth-form tab-pane fade show active  form-validation"
        >
          <form action="">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12 col-sm-12">
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Full Name<span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="James Wally"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Email<span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="hello@example.com"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Phone Number<span className="required">*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="+123456789"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Password<span className="required">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="***********"
                    />
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn btn-block btn-primary mt-3"
              onClick={studentRegistration}
              disabled={loading ? true : false}
            >
              {loading ? (
                <ColorRing
                  visible={true}
                  height="22"
                  width="22"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              ) : (
                "Register"
              )}
            </button>

            <div className="mt-3">
              Already have an account?{" "}
              <a href="/login" className="text-primary float-end mb-4">
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StudentRegistration;
