// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CONTACT FORM */
.styles_contactForm__AYgwR {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    font-family: Arial, sans-serif;
}

.styles_contactForm__AYgwR h2 {
    text-align: center;
    margin-bottom: 20px;
}

.styles_formGroup__euS7t {
    margin-bottom: 15px;
}

.styles_formGroup__euS7t label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.styles_formGroup__euS7t input,
.styles_formGroup__euS7t textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.styles_formGroup__euS7t textarea {
    resize: vertical;
    height: 100px;
}

.styles_errorInput__XMzup {
    border-color: red;
}

.styles_errorText__wuMP1 {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.styles_submitButton__oWd4F {
    width: 100%;
    padding: 10px;
    background-color: #67126B;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.styles_submitButton__oWd4F:hover {
    background-color: #67126B;
}`, "",{"version":3,"sources":["webpack://./src/components/landing/contactUs/styles.module.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,4BAA4B;IAC5B,kBAAkB;IAClB,8CAA8C;IAC9C,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;;IAEI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* CONTACT FORM */\n.contactForm {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    /* border: 1px solid #ddd; */\n    border-radius: 8px;\n    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\n    font-family: Arial, sans-serif;\n}\n\n.contactForm h2 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.formGroup {\n    margin-bottom: 15px;\n}\n\n.formGroup label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: bold;\n}\n\n.formGroup input,\n.formGroup textarea {\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 14px;\n}\n\n.formGroup textarea {\n    resize: vertical;\n    height: 100px;\n}\n\n.errorInput {\n    border-color: red;\n}\n\n.errorText {\n    color: red;\n    font-size: 12px;\n    margin-top: 5px;\n}\n\n.submitButton {\n    width: 100%;\n    padding: 10px;\n    background-color: #67126B;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    font-size: 16px;\n    cursor: pointer;\n}\n\n.submitButton:hover {\n    background-color: #67126B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactForm": `styles_contactForm__AYgwR`,
	"formGroup": `styles_formGroup__euS7t`,
	"errorInput": `styles_errorInput__XMzup`,
	"errorText": `styles_errorText__wuMP1`,
	"submitButton": `styles_submitButton__oWd4F`
};
export default ___CSS_LOADER_EXPORT___;
