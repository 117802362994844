import React, { useState, useEffect, useContext } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import Statistics from "../../components/dashboard/statistics/Statistics";
import Applications from "../../components/application/applications/Applications";
import Recent from "../../components/recent/Recent";
//
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { getStudents, getAdminOfficers } from "../../redux/reducers/user";
import {
  getPrograms,
  getProgramsByUniversities,
} from "../../redux/reducers/program";
import { getMatrix } from "../../redux/reducers/user";

import {
  FaUsers,
  FaUniversity,
  FaClipboardList,
  FaClipboardCheck,
} from "react-icons/fa";
import { FaUsersGear, FaTableList } from "react-icons/fa6";
import { BsClipboard2XFill, BsClipboard2PulseFill } from "react-icons/bs";
import { AuthContext } from "../../context/AuthContext";

const Dashboard = () => {
  const { role, userId, assignedUniversities } = useContext(AuthContext);
  const [students, setStudents] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [matrix, setMatrix] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleGetStudents = async (e) => {
    try {
      setLoading(true);
      await dispatch(getStudents()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          setStudents(res.payload);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAdmins = async (e) => {
    try {
      setLoading(true);
      await dispatch(getAdminOfficers()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          setAdmins(res.payload);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handlePrograms = async (e) => {
    try {
      setLoading(true);

      const action =
        role === "ADMISSION_OFFICER"
          ? getProgramsByUniversities(assignedUniversities)
          : getPrograms();

      await dispatch(action).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          setPrograms(res.payload);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetMatrix = async (e) => {
    try {
      setLoading(true);
      await dispatch(getMatrix({ role, userId, assignedUniversities })).then(
        (res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            if (res.payload.data) {
              setMatrix(res.payload);
            }
            setLoading(false);
            return;
          }
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetStudents();
    handleAdmins();
    handlePrograms();
    // handleGetMatrix();
  }, [role, userId, assignedUniversities]);

  useEffect(() => {
    handleGetMatrix();
  }, [role, userId, assignedUniversities]);

  const renderApplications = () => {
    if (role === "STUDENT") {
      return <Applications filter="STUDENT" id={userId} />;
    } else if (assignedUniversities && role === "ADMISSION_OFFICER") {
      return <Applications filter="ASSIGNED" id={assignedUniversities} />;
    } else if (role === "ADMIN" || role === "AECO_ADMIN") {
      return <Applications />;
    } else if (role === "COUNSELLOR") {
      return <Applications filter="COUNSELLOR" id={userId} />;
    } else if (role === "TEACHER") {
      return <Applications filter="TEACHER" id={userId} />;
    } else {
      return null; // or some fallback UI
    }
  };

  return (
    <DashboardLayout>
      <Recent programs={programs} students={students} admins={admins} />

      <Statistics
        index={1}
        iconOne={<FaClipboardList size={20} color="#fff" />}
        iconTwo={<BsClipboard2XFill size={25} color="#fff" />}
        iconThree={<BsClipboard2PulseFill size={50} color="#fff" />}
        iconFour={<FaClipboardCheck size={21} color="#fff" />}
        titleOne="Applications"
        titleTwo="Pending"
        titleThree="Processing"
        titleFour="Accepted"
        dataOne={matrix && matrix.data ? matrix.data.length : 0}
        dataTwo={
          matrix && matrix.data
            ? matrix.data.filter((item) => item.status === "PENDING").length
            : 0
        }
        dataThree={
          matrix && matrix.data
            ? matrix?.data.filter((item) => item.status === "PROCESSING").length
            : 0
        }
        dataFour={
          matrix && matrix.data
            ? matrix?.data.filter((item) => item.status === "ACCEPTED").length
            : 0
        }
        loading={loading}
        role={role}
      />

      <Statistics
        index={2}
        iconOne={<FaUsers size={25} color="#fff" />}
        iconTwo={<FaUsersGear size={25} color="#fff" />}
        iconThree={<FaUniversity size={25} color="#fff" />}
        iconFour={<FaTableList size={25} color="#fff" />}
        titleOne="Students"
        titleTwo="Admins"
        titleThree="Universities"
        titleFour="Programs"
        dataOne={matrix?.counts?.students || 0}
        dataTwo={matrix?.counts?.admins || 0}
        dataThree={matrix?.counts?.universities || 0}
        dataFour={matrix?.counts?.programs || 0}
        loading={loading}
        role={role}
      />
      {role && userId && renderApplications()}
    </DashboardLayout>
  );
};

export default Dashboard;
