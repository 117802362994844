// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-card {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.faq-header {
  /* background-color: #f7f7f7; */
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  /* padding: 15px; */
}
.accordion-header {
  padding: 0rem;
  border: 0.0625rem solid #f2f2f2;
  cursor: pointer;
  position: relative;
  color: #2b2b2b;
  font-weight: 400;
  border-radius: 0.375rem;
  transition: all 0.5s;
}

.faq-header h5 {
  margin-bottom: 0;
}

.faq-header i {
  float: right;
  font-size: 18px;
  transition: all 0.3s ease;
}

.faq-header:hover i {
  transform: rotate(180deg);
}

.faq-body {
  padding: 20px;
}

.question {
  font-weight: bold;
  margin-bottom: 10px;
}

.answer {
  margin-bottom: 20px;
}
.accordion-button:not(.collapsed) {
    color: #f0eef0;
    background-color: #67126B;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:focus {
    border-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/landing/faq/styles.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,uCAAuC;EACvC,aAAa;AACf;;AAEA;EACE,+BAA+B;EAC/B,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;AACA;IACI,cAAc;IACd,yBAAyB;IACzB,kGAAkG;AACtG;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".faq-card {\n  background-color: #fff;\n  border: none;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n}\n\n.faq-header {\n  /* background-color: #f7f7f7; */\n  border-bottom: 1px solid #ddd;\n  cursor: pointer;\n  /* padding: 15px; */\n}\n.accordion-header {\n  padding: 0rem;\n  border: 0.0625rem solid #f2f2f2;\n  cursor: pointer;\n  position: relative;\n  color: #2b2b2b;\n  font-weight: 400;\n  border-radius: 0.375rem;\n  transition: all 0.5s;\n}\n\n.faq-header h5 {\n  margin-bottom: 0;\n}\n\n.faq-header i {\n  float: right;\n  font-size: 18px;\n  transition: all 0.3s ease;\n}\n\n.faq-header:hover i {\n  transform: rotate(180deg);\n}\n\n.faq-body {\n  padding: 20px;\n}\n\n.question {\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n.answer {\n  margin-bottom: 20px;\n}\n.accordion-button:not(.collapsed) {\n    color: #f0eef0;\n    background-color: #67126B;\n    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);\n}\n.accordion-button:focus {\n    border-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
