import React, { useEffect, useState } from 'react';
import Layout from "../../layouts/Layout";
import { useDispatch } from "react-redux";
import { getTeacher } from "../../redux/reducers/user";
import toast from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";


const Teachers = () => {
    const dispatch = useDispatch();
    const [teachers, setTeacher] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchTeachers = async () => {
            setLoading(true);
            try {
                const res = await dispatch(getTeacher());
                if (res.meta.requestStatus === "rejected") {
                    toast.error(res.payload);
                } else {
                    setTeacher(res.payload);
                }
            } catch (error) {
                toast.error("An error occurred while fetching teachers");
            } finally {
                setLoading(false);
            }
        };

        fetchTeachers();
    }, []);
    console.log(teachers);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredTeachers = teachers.filter((teacher) =>
        teacher.fullName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Layout>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="page-title flex-wrap">
                                <div className="input-group search-area mb-md-0 mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <span className="input-group-text">
                                        <a href="javascript:void(0)">
                                            <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                                                    fill="#01A3FF"
                                                />
                                            </svg>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="row">
                                {loading ? (
                                    <div
                                        className="text-primary"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Loader />
                                    </div>
                                ) : filteredTeachers.length > 0 ? (
                                    filteredTeachers.map((item, index) => {
                                        return (
                                            <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                                                <div className="card contact_list text-center">
                                                    <div className="card-body">
                                                        <div className="user-content">
                                                            <div className="user-info">
                                                                <div className="user-img">
                                                                    <img
                                                                        src={
                                                                            (item.image &&
                                                                                JSON.parse(item?.image)?.Location) ||
                                                                            "./images/contacts/1.jpg"
                                                                        }
                                                                        alt=""
                                                                        className="avatar avatar-xl"
                                                                    />
                                                                </div>
                                                                <div className="user-details">
                                                                    <h4 className="user-name mb-0 ">
                                                                        {item.fullName}
                                                                    </h4>
                                                                    <p>
                                                                        {item.role === "TEACHER" && "Teacher"}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="contact-icon">
                                                            <span className="badge badge-success light me-2">
                                                                {item.teacherDetails.isVerified ? 'verified' : ''}
                                                            </span>

                                                            <span className="badge badge-primary light">
                                                                {item.teacherDetails.isCertified ? 'certified' : ''}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <a
                                                                href={`/teacher-detail?=${item._id}`}
                                                                className="btn  btn-primary btn-sm w-50 mx-auto"
                                                            >
                                                                <i className="fa-solid fa-user me-2"></i>Profile
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="text-center">No Data Found</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Teachers;
