import React, { useState } from "react";
import MainNavbar from "../../components/navbar/MainNavbar";
import FooterBottom from "../../components/landing/footerBottom/FooterBottom";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import Widget from "../../components/programSearch/widget/Widget";
import AMSWidget from "../../components/programSearch/amsWidget/AMSWidget";

const Landing = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("CAMEROON");

  const [program, setProgram] = useState([]);
  const [levelOfStudy, setLevelOfStudy] = useState([]);
  const [location, setLocation] = useState([]);

  const handleSearchPrograms = () => {
    try {
      const params = {
        name: JSON.stringify(program),
        location: JSON.stringify(location),
        levelOfStudy: JSON.stringify(levelOfStudy),
      };
      navigate(`/search-program?${new URLSearchParams(params).toString()}`);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className={styles.mainContainer}>
      <main className={styles.mainContent}>
        <MainNavbar />

        <div className={[styles.searchWidiget, "container"].join(" ")}>
          <div className={[styles.tabs]}>
            <span
              onClick={() => setActiveTab("CAMEROON")}
              className={activeTab === "CAMEROON" ? styles.activeTab : ""}
            >
              Cameroon
            </span>
            <span
              onClick={() => setActiveTab("ABROAD")}
              className={activeTab === "ABROAD" ? styles.activeTab : ""}
            >
              Abroad
            </span>
          </div>
          <div className={styles.searchWrapper}>
            <h2>FIND YOUR PREFERED STUDY PROGRAMME & UNIVERSITY</h2>
            <div className={[styles.widgetWrapper]}>
              {activeTab === "CAMEROON" && (
                <div>
                  <Widget
                    setProgram={setProgram}
                    setLocation={setLocation}
                    setLevelOfStudy={setLevelOfStudy}
                    onClick={handleSearchPrograms}
                  />
                </div>
              )}

              {activeTab === "ABROAD" && (
                <div className={styles.amsWidget}>
                  <AMSWidget />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container">
          <div className={styles.featuresList}>
            <p>Search Programmes</p>
            <p>Submit Applications</p>
            <p>Pay Application fees</p>
            <p>Check the status of your applicatiion</p>
            <p>Recieve your Admission Letter</p>
          </div>
        </div>
      </main>

      <FooterBottom />
    </div>
  );
};

export default Landing;
