import React from "react";
import styles from "./styles.module.css";

const FooterBottom = () => {
  return (
    <div className={styles.footerbottom}>
      <p>
        <a href="#">Terms Of Service</a>
        <span>
          <a href="#">Privacy Policy</a>
        </span>
        &copy; CampusCamer. All rights reserved.
      </p>
    </div>
  );
};

export default FooterBottom;
