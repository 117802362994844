import React from "react";
import "./institution.css";
import MainNavbar from "../../components/navbar/MainNavbar";
import Footer from "../../components/landing/footer/Footer";

const InstitutionLanding = () => {
  return (
    <>
      <MainNavbar />
      <div className="institude-hero bg-primary">
        <div className="container py-4">
          <div className="row">
            <div className="col-md-6 d-none d-md-block">
              <img
                src="/images/institution.png"
                alt=""
                className=" object-fit-contain w-100"
              />
            </div>
            <div className="col-md-6 my-auto">
              <div className="">
                <h1 className="text-white text-md-start text-center">
                  Find Top Educators for Your Institution
                </h1>
                <p className="text-white text-center text-md-start fs-3">
                  Easily browse, verify, and hire qualified teachers through our
                  platform.
                </p>
                <div className="text-center text-md-start">
                  <a href="/institution/hire-teachers" className="btn btn-light">
                    Start Hiring Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our-platform bg-white">
        <div className="container py-4 px-md-5">
          <h2 className="text-primary text-center">Why Hire Through Us?</h2>
          <div className="py-4">
            <p className=" text-center text-black">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Expedita, hic illum voluptatem magnam in consectetur. Aspernatur
              deserunt molestiae eos distinctio perspiciatis rem, voluptatum
              obcaecati soluta mollitia voluptatem officia, delectus modi?
            </p>
          </div>
          <div className="py-3 text-center">
            <h2 className="text-primary">Benefits Of Hiring with us</h2>
            <p className=" text-center text-black">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur
              magnam eveniet doloremque aut accusantium dolor laborum vel qui
              repudiandae, magni similique illo porro quae aliquam quaerat
              necessitatibus tempora pariatur eum.
            </p>
            <p className=" text-center text-black">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam,
              a est corrupti cumque quam dolore consequuntur? Delectus aperiam
              consectetur autem dolor quaerat excepturi qui, vel, perferendis,
              eligendi soluta optio cupiditate.
            </p>
          </div>
          <div className="py-3 text-center">
            <h2 className="text-primary">Our Verified Teachers for Hire</h2>
            <p className=" text-center text-black">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur
              magnam eveniet doloremque aut accusantium dolor laborum vel qui
              repudiandae, magni similique illo porro quae aliquam quaerat
              necessitatibus tempora pariatur eum.
            </p>
            <p className=" text-center text-black">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam,
              a est corrupti cumque quam dolore consequuntur? Delectus aperiam
              consectetur autem dolor quaerat excepturi qui, vel, perferendis,
              eligendi soluta optio cupiditate.
            </p>
          </div>
        </div>
      </div>
      <div className="hiring-process py-5">
        <div className="container">
          <div className="">
            <div className="">
              <h2 className="text-primary text-center">How It Works</h2>
              <div className="py-2">
                <h3 className="text-center text-primary pb-2">
                  A Step-by-Step Guide to Hiring Teachers on Our Platform
                </h3>
                <div className="row">
                  <div className="col-md-6 text-md-start text-center">
                    <dl>
                      <dt className="text-primary fs-3">
                        1. Browse Our Extensive Teacher Database:
                      </dt>
                      <dd className=" text-black fs-4 py-2">
                        - Use our advanced search filters to find teachers that
                        match your specific requirements, such as subject
                        expertise, experience level, and location.
                      </dd> 
                      <dd className=" text-black fs-4 py-2">
                        - Explore teacher profiles to view their qualifications,
                        certifications, and teaching philosophy.
                      </dd>
                      <dt className="text-primary fs-3">2. Verify Credentials:</dt>
                      <dd className=" text-black fs-4 py-2">
                        - Check teachers' credentials and certifications to
                        ensure they meet your institution's standards.
                      </dd>
                      <dd className=" text-black fs-4 py-2">
                        - Request additional documentation if needed, such as
                        transcripts or references.
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-6 text-md-start text-center">
                    <dl>
                      <dt className="text-primary fs-3">3. Interview Candidates:</dt>
                      <dd className=" text-black fs-4 py-2">
                        - Schedule interviews with shortlisted teachers to
                        assess their suitability for your institution.
                      </dd>
                      <dd className=" text-black fs-4 py-2">
                        - Prepare a list of questions to evaluate their teaching
                        skills, subject knowledge, and compatibility with your
                        school's culture.
                      </dd>
                      <dt className="text-primary fs-3">
                        4. Hire the Right Teacher:
                      </dt>
                      <dd className=" text-black fs-4 py-2">
                        - Once you've found the perfect candidate, proceed with
                        the hiring process, including negotiating terms and
                        conditions and completing necessary paperwork.s.
                      </dd>
                      <dt className="text-primary fs-3">
                        5. Onboarding and Support:
                      </dt>
                      <dd className=" text-black fs-4 py-2">
                        - Provide new teachers with the necessary resources and
                        support to ensure a smooth transition into your
                        institution.
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mx-auto py-3 col-md-8">
              <img
                src="/images/hireteachersteps.png"
                alt=""
                className=" object-fit-contain w-100"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="verified-teacher bg-white py-5">
        <div className="container">
          <h2 className="text-primary py-3 text-center">
            Access to Verified Teachers
          </h2>
          <div className="py-2 text-center">
            <h4 className=" text-primary">Our Verification Process</h4>
            <div className="py-2">
              <p className="text-black">
                We understand the importance of ensuring that the teachers on our
                platform are trustworthy, qualified, and dedicated professionals.
                That's why we have a rigorous verification process in place to
                guarantee that our teachers meet the highest standards. Our
                verification process involves a thorough review of each teacher's
                credentials, qualifications, and experience. We require teachers
                to provide documentation, such as degree certificates, teaching
                certifications, and references, which are then carefully vetted by
                our team. Additionally, we conduct thorough background checks to
                ensure that our teachers are safe and reliable.
              </p>
            </div>
          </div>
          <div className="py-2 text-center">
            <h4 className=" text-primary">Benefits for Institutions</h4>
            <div className="py-2">
              <p className="text-black">
                Our verification process provides institutions with quality
                assurance, accountability, and trust and confidence in the
                teachers they hire. By verifying our teachers, we ensure that they
                possess the necessary skills, knowledge, and experience to provide
                high-quality education. Our verification process also holds
                teachers accountable for their actions and performance, promoting
                a culture of excellence and professionalism. Moreover, our
                verification process minimizes the risk of hiring unqualified or
                unreliable teachers, protecting institutions from potential
                reputational damage.
              </p>
            </div>
            <div className="py-3 text-center">
              <a href="/institution/hire-teachers" className="btn btn-primary px-3 py-2 fs-5">Browse Verified Teachers</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InstitutionLanding;
