// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_hero__6uPrE {
    padding: 60px 0;
}

.styles_header__T4Mo9 {
    text-align: center;
    font-size: 40px;
}

.styles_subHeader__xn\\+I\\+ {
    font-size: 20px;
    color: #000;
    text-align: center;
}

/* CONTACT FORM */
.styles_contactForm__Tgs7u {
    background-color: #fff;
    padding: 60px 0;
}

.styles_contactInfo__KlHFD {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.styles_contactInfo__KlHFD p {
    color: #000;
    font-size: 18px;
    margin-top: 10px;
}

.styles_contactItem__TN40H {
    text-align: center;
}

.styles_contactInfo__KlHFD .styles_contactItem__TN40H span {
    background-color: #67126B;
    color: #fff;
    padding: 7px 10px;
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/contactUs/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;AACtB;;AAEA,iBAAiB;AACjB;IACI,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".hero {\n    padding: 60px 0;\n}\n\n.header {\n    text-align: center;\n    font-size: 40px;\n}\n\n.subHeader {\n    font-size: 20px;\n    color: #000;\n    text-align: center;\n}\n\n/* CONTACT FORM */\n.contactForm {\n    background-color: #fff;\n    padding: 60px 0;\n}\n\n.contactInfo {\n    display: flex;\n    justify-content: space-around;\n    flex-wrap: wrap;\n    margin-bottom: 40px;\n}\n\n.contactInfo p {\n    color: #000;\n    font-size: 18px;\n    margin-top: 10px;\n}\n\n.contactItem {\n    text-align: center;\n}\n\n.contactInfo .contactItem span {\n    background-color: #67126B;\n    color: #fff;\n    padding: 7px 10px;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `styles_hero__6uPrE`,
	"header": `styles_header__T4Mo9`,
	"subHeader": `styles_subHeader__xn+I+`,
	"contactForm": `styles_contactForm__Tgs7u`,
	"contactInfo": `styles_contactInfo__KlHFD`,
	"contactItem": `styles_contactItem__TN40H`
};
export default ___CSS_LOADER_EXPORT___;
