import React, { useState, useEffect } from "react";
import Layout from "../../layouts/Layout";
import Tab from "../../components/application/tab/Tab";
import Payments from "../../components/payments/Payments";
import moment from "moment";

import Loader from "../../components/Loader/Loader";
import Modal from "../../components/modal/Modal";
import { useLocation } from "react-router-dom";
import { getApplication } from "../../redux/reducers/application";
import { getProgram } from "../../redux/reducers/program";
import { getUniversity } from "../../redux/reducers/university";
import { getUser } from "../../redux/reducers/user";
import { payRegFee } from "../../redux/reducers/payments";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

const ApplicationDetails = () => {
  const { search } = useLocation();
  const id = search.split("?=")[1];

  const [application, setApplication] = useState(null);
  const [program, setProgram] = useState(null);
  const [university, setUniversity] = useState(null);
  const [student, setStudent] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleGetApplication = async (e) => {
    try {
      if (id) {
        setLoading(true);
        await dispatch(getApplication(id)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            setApplication(res.payload);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetProgram = async (e) => {
    try {
      if (id) {
        setLoading(true);
        await dispatch(getProgram(application.programId)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            setProgram(res.payload);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetUniversity = async (e) => {
    try {
      if (id) {
        setLoading(true);
        await dispatch(getUniversity(application.universityId)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            setUniversity(res.payload);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetStudent = async (e) => {
    try {
      if (id) {
        setLoading(true);
        await dispatch(getUser(application.studentId)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            setStudent(res.payload);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRegFee = async () => {
    try {
      if (phoneNumber) {
        const data = {
          mobileWalletNumber: phoneNumber,
          studentId: student._id,
          programName: program.name,
          studentName: student.fullName,
          applicationId: application._id,
        };
        setPaymentLoading(true);
        await dispatch(payRegFee(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setPaymentLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.reload();
            setPaymentLoading(false);
            return;
          }
        });
      } else {
        toast.error("Phone number is required");
        return;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetApplication();
  }, [id]);

  useEffect(() => {
    handleGetProgram();
    handleGetUniversity();
    handleGetStudent();
  }, [application]);

  console.log(application);

  return (
    <Layout>
      <div className="row">
        <div className="col-xl-12">
          <div className="card h-auto">
            <div className="card-header p-0">
              <div className="user-bg w-100">
                <div className="user-svg">
                  <svg
                    width="264"
                    height="109"
                    viewBox="0 0 264 109"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.0107422"
                      y="0.6521"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FCC43E"
                    />
                  </svg>
                </div>
                <div className="user-svg-1">
                  <svg
                    width="264"
                    height="59"
                    viewBox="0 0 264 59"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.564056"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FB7D5B"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {loading || !application || !student || !program ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            ) : (
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    {/* <h5>REGISTRATION </h5> */}
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#PayRegFee"
                      disabled={application.isPaid}
                    >
                      PAY FEE NOW
                    </button>

                    <Modal
                      id="PayRegFee"
                      title="Pay Registration Fee"
                      loading={paymentLoading}
                      btnFunction={handleRegFee}
                    >
                      <div>
                        <label htmlFor="">Enter Mobile Money Number</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="6XXXXXXXX"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                    </Modal>
                  </div>
                  {/* <div className="dropdown custom-dropdown">
                    <div
                      className="btn sharp tp-btn "
                      data-bs-toggle="dropdown"
                    >
                      <svg
                        width="24"
                        height="6"
                        viewBox="0 0 24 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0012 0.359985C11.6543 0.359985 11.3109 0.428302 10.9904 0.561035C10.67 0.693767 10.3788 0.888317 10.1335 1.13358C9.88829 1.37883 9.69374 1.67 9.56101 1.99044C9.42828 2.31089 9.35996 2.65434 9.35996 3.00119C9.35996 3.34803 9.42828 3.69148 9.56101 4.01193C9.69374 4.33237 9.88829 4.62354 10.1335 4.8688C10.3788 5.11405 10.67 5.3086 10.9904 5.44134C11.3109 5.57407 11.6543 5.64239 12.0012 5.64239C12.7017 5.64223 13.3734 5.36381 13.8686 4.86837C14.3638 4.37294 14.6419 3.70108 14.6418 3.00059C14.6416 2.3001 14.3632 1.62836 13.8677 1.13315C13.3723 0.637942 12.7004 0.359826 12 0.359985H12.0012ZM3.60116 0.359985C3.25431 0.359985 2.91086 0.428302 2.59042 0.561035C2.26997 0.693767 1.97881 0.888317 1.73355 1.13358C1.48829 1.37883 1.29374 1.67 1.16101 1.99044C1.02828 2.31089 0.959961 2.65434 0.959961 3.00119C0.959961 3.34803 1.02828 3.69148 1.16101 4.01193C1.29374 4.33237 1.48829 4.62354 1.73355 4.8688C1.97881 5.11405 2.26997 5.3086 2.59042 5.44134C2.91086 5.57407 3.25431 5.64239 3.60116 5.64239C4.30165 5.64223 4.97339 5.36381 5.4686 4.86837C5.9638 4.37294 6.24192 3.70108 6.24176 3.00059C6.2416 2.3001 5.96318 1.62836 5.46775 1.13315C4.97231 0.637942 4.30045 0.359826 3.59996 0.359985H3.60116ZM20.4012 0.359985C20.0543 0.359985 19.7109 0.428302 19.3904 0.561035C19.07 0.693767 18.7788 0.888317 18.5336 1.13358C18.2883 1.37883 18.0937 1.67 17.961 1.99044C17.8283 2.31089 17.76 2.65434 17.76 3.00119C17.76 3.34803 17.8283 3.69148 17.961 4.01193C18.0937 4.33237 18.2883 4.62354 18.5336 4.8688C18.7788 5.11405 19.07 5.3086 19.3904 5.44134C19.7109 5.57407 20.0543 5.64239 20.4012 5.64239C21.1017 5.64223 21.7734 5.36381 22.2686 4.86837C22.7638 4.37294 23.0419 3.70108 23.0418 3.00059C23.0416 2.3001 22.7632 1.62836 22.2677 1.13315C21.7723 0.637942 21.1005 0.359826 20.4 0.359985H20.4012Z"
                          fill="#A098AE"
                        />
                      </svg>
                    </div>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a className="dropdown-item" target="_blank" href="#">
                        Send Email
                      </a>
                      <a className="dropdown-item" target="_blank" href="#">
                        WhatsApp
                      </a>
                    </div>
                  </div> */}
                </div>
                <div className="row mt-2">
                  <div className="col-xl-3 col-xxl-6 col-sm-6">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/profile.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>Program Name:</span>
                        <h5 className="mb-0">{application?.programName}</h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-xxl-6 col-sm-6">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/svg/location.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>Application Status:</span>
                        <h5
                          className={`date ${
                            application?.status === "PENDING"
                              ? "text-warning"
                              : application?.status === "PROCESSING"
                              ? "text-dark"
                              : application?.status === "ACCEPTED"
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {application?.status === "PENDING"
                            ? "PENDING"
                            : application?.status === "PROCESSING"
                            ? "CONDITIONAL ACCEPTANCE"
                            : application?.status === "ACCEPTED"
                            ? "FAL APPROVED"
                            : "REJECTED"}
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-xxl-6 col-sm-6 mt-4">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/profile.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>Registration Fee:</span>
                        <h5
                          className={
                            application.isPaid ? "text-success" : "text-danger"
                          }
                        >
                          {application?.isPaid ? "PAID" : "NOT PAID"}
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-xxl-6 col-sm-6 mt-4">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/svg/location.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>Application Date:</span>
                        <h5 className="mb-0">
                          {moment(application?.createdAt).format("DD-MM-YYYY")}
                        </h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>

          {loading || !application || !student || !program ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <Tab
              program={program}
              university={university}
              student={student}
              application={application}
            />
          )}

          {/* Payments */}
          <Payments filter="APPLICATION" id={id} />
        </div>
      </div>
    </Layout>
  );
};

export default ApplicationDetails;
