import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_VERSION}/applications`;
const token = localStorage.getItem("campus-camer-user");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const initialState = {};

export const getApplications = createAsyncThunk("applications/getApplications", async (thunkAPI) => {
    try {
        const response = await axios.get(`${url}/`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getApplication = createAsyncThunk("applications/getApplication", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/details/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getUniversityApplications = createAsyncThunk("applications/getApplication", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/universities/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getAssignedUniversitiesApplications = createAsyncThunk("applications/getAssignedUniversitiesApplications", async (universityIds, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/assigned-universities`, {
            params: { universityIds },
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getProgramApplications = createAsyncThunk("applications/getApplication", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/programs/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getStudentApplications = createAsyncThunk("applications/getApplication", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/students/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getCounsellorApplications = createAsyncThunk("applications/getApplication", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/counsellors/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getTeacherApplications = createAsyncThunk("applications/getApplication", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/teachers/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addApplication = createAsyncThunk("applications/addApplication", async (data, thunkAPI) => {
    try {
        const response = await axios.post(`${url}/create`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateApplication = createAsyncThunk("applications/updateApplication", async (data, thunkAPI) => {
    try {
        const response = await axios.put(`${url}/update/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const applicationSlice = createSlice({
    name: "application",
    initialState,
    reducers: {},
    extraReducers: (builder) => { },
});

export const { } = applicationSlice.actions;
export default applicationSlice.reducer;
