import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Carousel } from "react-bootstrap";
import "./styles.css";

const TeacherCarousel = () => {
  const testimonials = [
    {
      quote:
        "I was skeptical at first, but after getting verified, I started receiving job offers from top institutions. It's been a game-changer for my career.",
      author: "John Lee",
      title: "Verified Teacher",
      image: "/images/landing/programs_near.png",
    },
    {
      quote:
        "The verification process was straightforward and efficient. I'm now confident in my teaching abilities and have landed a dream job!",
      author: "Emily Chen",
      title: "Verified Teacher",
      image: "/images/landing/programs_near.png",
    },
    {
      quote:
        "I was hesitant to pay for verification, but it's been worth every penny. I've received multiple job offers and have a renewed sense of confidence in my teaching skills.",
      author: "Michael Brown",
      title: "Verified Teacher",
      image: "/images/landing/programs_near.png",
    },
  ];

  return (
    <Carousel>
      {testimonials.map((testimonial, index) => (
        <Carousel.Item key={index}>
          <img
            src={testimonial.image}
            alt={testimonial.author}
            className="testimonial-image"
          />
          <div className="testimonial">
            <div className="testimonial-content">
              <p>{testimonial.quote}</p>
            </div>
            <div className="testimonial-author">
              <strong>{testimonial.author}</strong>
              <span>{testimonial.title}</span>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default TeacherCarousel;
