import React from "react";
import Loader from "../../Loader/Loader";

const Statistics = ({
  index,
  iconOne,
  iconTwo,
  iconThree,
  iconFour,
  titleOne,
  titleTwo,
  titleThree,
  titleFour,
  dataOne,
  dataTwo,
  dataThree,
  dataFour,
  loading,
  role,
}) => {
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Loader />
            </div>
          ) : (
            <div className="card-body pb-xl-4 pb-sm-3 pb-0">
              <div className="row">
                <div className="col-xl-3 col-6">
                  <div className="content-box">
                    <div className="icon-box icon-box-xl std-data">
                      {iconOne}
                    </div>
                    <div className="chart-num">
                      <p>{titleOne}</p>
                      <h2 className="font-w700 mb-0">{dataOne}</h2>
                    </div>
                  </div>
                </div>
                {index === 2 ? (
                  (role === "ADMIN" || role === "AECO_ADMIN") && (
                    <div className="col-xl-3 col-6">
                      <div className="content-box">
                        <div className="teach-data icon-box icon-box-xl">
                          {iconTwo}
                        </div>
                        <div className="chart-num">
                          <p>{titleTwo}</p>
                          <h2 className="font-w700 mb-0">{dataTwo}</h2>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="col-xl-3 col-6">
                    <div className="content-box">
                      <div className="teach-data icon-box icon-box-xl">
                        {iconTwo}
                      </div>
                      <div className="chart-num">
                        <p>{titleTwo}</p>
                        <h2 className="font-w700 mb-0">{dataTwo}</h2>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-xl-3 col-6">
                  <div className="content-box">
                    <div className="event-data icon-box icon-box-xl">
                      {iconThree}
                    </div>
                    <div className="chart-num">
                      <p>{titleThree}</p>
                      <h2 className="font-w700 mb-0">{dataThree}</h2>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-6">
                  <div className="content-box">
                    <div className="food-data icon-box icon-box-xl bg-dark">
                      {iconFour}
                    </div>
                    <div className="chart-num">
                      <p>{titleFour}</p>
                      <h2 className="font-w700 mb-0">{dataFour}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
