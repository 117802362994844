import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { role } = useContext(AuthContext);
  const natItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
      icon: <i className="material-symbols-outlined">dashboard</i>,
      sub: [],
    },
    {
      title: "Universities",
      link: "/universities",
      icon: <i className="material-symbols-outlined">home</i>,
      sub: [],
    },
    {
      title: "Programs",
      link: "/programs",
      icon: <i className="material-symbols-outlined">category</i>,
      sub: [],
    },

    {
      title: "Applications",
      link: "/applications",
      icon: <i className="material-symbols-outlined">book</i>,
      sub: [],
    },
    role !== "STUDENT" &&
      role !== "ADMISSION_OFFICER" && {
        title: "Students",
        link: "/students",
        icon: <i className="material-symbols-outlined">school</i>,
        sub: [],
      },
    role !== "STUDENT" &&
      role !== "TEACHER" &&
      role !== "COUNSELLOR" &&
      role !== "ADMISSION_OFFICER" && {
        title: "Admission Officers",
        link: "/admins",
        icon: <i className="material-symbols-outlined">person</i>,
        sub: [],
      },
    role !== "STUDENT" &&
      role !== "TEACHER" &&
      role !== "COUNSELLOR" &&
      role !== "ADMISSION_OFFICER" && {
        title: "Teachers",
        link: "/admin-teachers",
        icon: <i className="material-symbols-outlined">person</i>,
        sub: [],
      },
    // role === "ADMIN" && {
    //    title: "Emails",
    //    link: "/emails",
    //    icon: <i className="material-symbols-outlined">email</i>,
    //    sub: [],
    //  },
    // {
    //   title: "Settings",
    //   link: "/settings",
    //   icon: <i className="material-symbols-outlined">settings</i>,
    //   sub: [],
    // },
  ].filter(Boolean);

  const toggleSubMenu = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="dlabnav">
      <div className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          {natItems.map((item, index) => {
            return (
              <li key={index}>
                <a
                  className={
                    item.sub.length > 0 ? "has-arrow cursor-pointer" : ""
                  }
                  onClick={() => toggleSubMenu(index)}
                  href={item.link}
                >
                  {item.icon}
                  <span className="nav-text">{item.title}</span>
                </a>
                {activeIndex === index && item.sub.length > 0 && (
                  <ul className="sub-menu" aria-expanded="false">
                    {item.sub.map((subitem, idx) => {
                      return (
                        <li key={idx}>
                          <a href={subitem.link}>{subitem.title}</a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
        <div className="copyright">
          <p>
            <strong>School Admission Dashboard</strong>
          </p>
          <p className="fs-12">
            <a href="http://saassimplified.net/" target="_blank">
              Made with <span className="heart"></span> by Saas Simplified
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
