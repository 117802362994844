import React, { useState, useContext } from "react";
import styles from "./Navbar.module.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import ChangeLanguage from "../../i18next/changeLanguage/ChangeLanguage";
import { Navbar, Nav, NavItem, Container, Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const MainNavbar = () => {
  const { t } = useTranslation();
  const expand = "lg";

  const { authenticated } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const [activeLink, setActiveLink] = useState("students");

  const handleLinkClick = (link) => {
    setActiveLink(link);
    console.log(link);
  };

  return (
    <>
      <Navbar
        expand={expand}
        variant="dark"
        sticky="top"
        style={{ backgroundColor: "#67126B", padding: "0px" }}
      >
        <Container className="navbar-container">
          <Navbar.Brand
            href="#"
            className="d-lg-none"
            style={{ padding: "30px" }}
          ></Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
            style={{ backgroundColor: "#67126B", padding: "19px 0px" }}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${expand}`}
              ></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav>
                <NavItem>
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? `active ${styles.linkStyleActive}`
                        : styles.linkStyle
                    }
                    style={{
                      fontSize: "20px",
                      fontWeight: 500,
                      textDecoration: "none",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    For Students
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/teachers"
                    className={({ isActive }) =>
                      isActive
                        ? `active  ${styles.linkStyleActive}`
                        : styles.linkStyle
                    }
                    style={{
                      fontSize: "20px",
                      fontWeight: 500,
                      textDecoration: "none",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    For Teacher
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/alumni"
                    className={({ isActive }) =>
                      isActive
                        ? `active  ${styles.linkStyleActive}`
                        : styles.linkStyle
                    }
                    style={{
                      fontSize: "20px",
                      fontWeight: 500,
                      textDecoration: "none",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    For Alumni
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/institution"
                    className={({ isActive }) =>
                      isActive
                        ? `active  ${styles.linkStyleActive}`
                        : styles.linkStyle
                    }
                    style={{
                      fontSize: "20px",
                      fontWeight: 500,
                      textDecoration: "none",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    For Institution
                  </NavLink>
                </NavItem>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <nav className={`${styles.navbar} sticky-top`}>
        <div className={`container ${styles.navbar_container}`}>
          <div className={styles.logo}>
            <a href="/">
              <img src="/logos/logo_color.png" alt="Logo" />
            </a>
          </div>
          {/* <div className={styles.links}>
            <a href="#home" className={styles.link}>
              Home
            </a>
            <a href="#about" className={styles.link}>
              About
            </a>
            <a href="#services" className={styles.link}>
              Services
            </a>
            <a href="#contact" className={styles.link}>
              Contact
            </a>
            <a href="/search-program" className={styles.link}>
              Search Programs
            </a>
            <a>{t("greeting")}</a>
          </div> */}
          <div className={styles.buttons}>
            <ChangeLanguage />
            {authenticated ? (
              <a href="/dashboard" className={styles.login}>
                Account
              </a>
            ) : (
              <>
                <a href="/login" className={styles.login}>
                  Login
                </a>
                <a href="/register" className={styles.signup}>
                  Sign Up
                </a>
              </>
            )}
          </div>
          <div className={styles.menuIcon}>
            <ChangeLanguage />
            <div onClick={toggleSidebar}>
              {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
        </div>
      </nav>

      <div
        className={`${styles.sidebar} ${
          isSidebarOpen ? styles.showSidebar : ""
        }`}
      >
        <button className={styles.closeButton} onClick={closeSidebar}>
          <FaTimes color="#67126B" />
        </button>
        <a href="/" className={styles.sidebarLink}>
          Home
        </a>
        <a href="#why_campus_camer" className={styles.sidebarLink}>
          Why Choose Campus Camer
        </a>
        <a href="#programs_near_you" className={styles.sidebarLink}>
          Amazing Programs Near You
        </a>
        <a href="#featured_programs" className={styles.sidebarLink}>
          Featured Institution
        </a>
        {/* <a href="#registration_steps" className={styles.sidebarLink}>
          How To Apply
        </a>
        <a href="#testimonial" className={styles.sidebarLink}>
          What Our Satisfied Students Say
        </a> */}
        {authenticated ? (
          <a href="/dashboard" className={styles.sidebarButtonLogin}>
            Account
          </a>
        ) : (
          <>
            <a href="/login" className={styles.sidebarButtonLogin}>
              Login
            </a>
            <a href="/signup" className={styles.sidebarButtonSignup}>
              Signup
            </a>
          </>
        )}
      </div>
    </>
  );
};

export default MainNavbar;
