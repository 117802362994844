import React, { useEffect, useRef } from "react";

const AMSWidget = () => {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://studentpanel.net/widget/ams-widget-loader.js";
    script.setAttribute("ag", "13059");
    script.setAttribute("wid", "105");
    script.async = true;
    script.className = "amsSearch";
    widgetContainerRef.current?.appendChild(script);
  }, []);

  return (
    <div>
      <div
        ref={widgetContainerRef}
        id="script-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
};

export default AMSWidget;
