import React, { useState, useContext } from "react";
import { updateApplication } from "../../../redux/reducers/application";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";

import GenericPdfDownloader from "../../letters/Letters";
import ConditionalLetter from "../../letters/ConditionalLetter";
import FinalLetter from "../../letters/FinalLetter";

import { AuthContext } from "../../../context/AuthContext";

const Settings = ({ application, student, program, university }) => {
  const { role, authorizationLevel } = useContext(AuthContext);
  const [status, setStatus] = useState("");
  const [rejectionRemark, setRemark] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateApplication = async (e) => {
    e.preventDefault();
    try {
      if (status && status === "ACCEPTED") {
        if (role === "AECO_ADMIN") {
          if (authorizationLevel !== 1) {
            toast.error("Unauthorised");
            return;
          }
        }
      }
      if (status && status === "REJECTED" && !rejectionRemark) {
        toast.error("Please enter a reason");
        return;
      }
      setLoading(true);
      const data = {
        id: application._id,
        studentEmail: student.emailAddress,
        status: status,
        rejectionRemark: rejectionRemark,
      };
      await dispatch(updateApplication(data)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          toast.success(res.payload.message);
          setLoading(false);
          window.location.reload();
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <div id="settings" className="tab-pane fade">
      <div className="pt-3">
        <div className="settings-form">
          <div className="row mb-4">
            <h5 className="text-primary">Offer Letters</h5>

            <div className="mb-3 col-md-12">
              <label className="form-label mx-2">
                Conditional Offer Letter
              </label>
              {(application.status === "ACCEPTED" ||
                application.status === "PROCESSING") && (
                <ConditionalLetter
                  student={student}
                  program={program}
                  application={application}
                  university={university}
                />
              )}

              <GenericPdfDownloader
                downloadFileName="Conditional_Offer_Letter"
                rootElementId="conditional_offer_letter"
                imageId="letter_logo"
                imageLink={
                  university?.letterHead &&
                  JSON.parse(university.image).Location
                }
                disabled={
                  application.status === "ACCEPTED"
                    ? false
                    : application.status === "PROCESSING"
                    ? false
                    : true
                }
              />
            </div>
            <hr />
            <div className="mb-3 col-md-12">
              <label className="form-label mx-2">Final Acceptance Letter</label>
              {application.status === "ACCEPTED" && (
                <FinalLetter
                  student={student}
                  program={program}
                  application={application}
                  university={university}
                />
              )}
              <GenericPdfDownloader
                downloadFileName="Final_Acceptance_Letter"
                rootElementId="final_offer_letter"
                imageId="letter_head"
                imageLink={
                  university?.letterHead &&
                  JSON.parse(university.letterHead).Location
                }
                disabled={application.status === "ACCEPTED" ? false : true}
              />
            </div>
          </div>

          {role !== "STUDENT" && role !== "COUNSELLOR" && (
            <>
              <h5 className="text-primary">Application Settings</h5>
              <form>
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label className="form-label">Application Status</label>
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      {/* <option value="PENDING">PENDING</option> */}
                      <option value="PROCESSING">PROCESSING</option>
                      <option value="ACCEPTED">ACCEPTED</option>
                      <option value="REJECTED">REJECTED</option>
                    </select>
                  </div>
                  {status === "REJECTED" && (
                    <div className="mb-3 col-md-12">
                      <label className="form-label">
                        Reason for rejection<span class="required">*</span>
                      </label>
                      <textarea
                        placeholder="Enter remark..."
                        className="form-control"
                        rows="6"
                        value={rejectionRemark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleUpdateApplication}
                    disabled={loading}
                  >
                    {loading ? (
                      <ColorRing
                        visible={true}
                        height="22"
                        width="22"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
