// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
}

.testimonial-content {
  font-style: italic;
  font-size: 18px;
  margin-bottom: 10px;
}

.testimonial-author {
  font-size: 16px;
  color: #333;
}

.testimonial-author strong {
  font-weight: bold;
  font-size: 18px;
}

.carousel {
  max-width: 800px;
  margin: 40px auto;
}

.carousel .carousel-inner {
  padding: 20px;
}

.carousel .carousel-item {
  padding: 20px;
  border-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #67126b;
  border: none;
  cursor: pointer;
}

.carousel .carousel-control-prev:hover,
.carousel .carousel-control-next:hover {
  background-color: #67126b;
}

.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/landing/teacherTestimonials/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".testimonial {\n  background-color: #f7f7f7;\n  padding: 20px;\n  border-radius: 10px;\n  margin: 20px;\n}\n\n.testimonial-content {\n  font-style: italic;\n  font-size: 18px;\n  margin-bottom: 10px;\n}\n\n.testimonial-author {\n  font-size: 16px;\n  color: #333;\n}\n\n.testimonial-author strong {\n  font-weight: bold;\n  font-size: 18px;\n}\n\n.carousel {\n  max-width: 800px;\n  margin: 40px auto;\n}\n\n.carousel .carousel-inner {\n  padding: 20px;\n}\n\n.carousel .carousel-item {\n  padding: 20px;\n  border-radius: 10px;\n  background-color: #f7f7f7;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.carousel .carousel-control-prev,\n.carousel .carousel-control-next {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: #67126b;\n  border: none;\n  cursor: pointer;\n}\n\n.carousel .carousel-control-prev:hover,\n.carousel .carousel-control-next:hover {\n  background-color: #67126b;\n}\n\n.testimonial-image {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  margin: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
