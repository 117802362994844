import React from "react";
import styles from "./styles.module.css";
import MainNavbar from "../../components/navbar/MainNavbar";
import FooterBottom from "../../components/landing/footerBottom/FooterBottom";
import ContactForm from "../../components/landing/contactUs/ContactForm";
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

const contactUs = () => {
  return (
    <div>
      <MainNavbar />

      <div className={styles.hero}>
        <div className="container">
          <h1 className={styles.header}>Contact Us</h1>
          <p className={styles.subHeader}>
            We're here to help you find the perfect education and career
            development program for your Cameroonian student.
          </p>
        </div>
      </div>

      <div className={styles.contactForm}>
        <div className="container">
          <div className={styles.contactInfo}>
            <div className={styles.contactItem}>
              <span>
                <FaEnvelope />
              </span>
              <p> 123@example.com</p>
            </div>
            <div className={styles.contactItem}>
              <span>
                <FaPhone />
              </span>
              <p> 00223 4567890</p>
            </div>
            <div className={styles.contactItem}>
              <span>
                <FaMapMarkerAlt />
              </span>
              <p> 123 Main Street, Cameroon</p>
            </div>
          </div>
          <ContactForm />
        </div>
      </div>

      <FooterBottom />
    </div>
  );
};

export default contactUs;
