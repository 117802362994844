import React, { useState, useEffect } from "react";
import MainNavbar from "../../components/navbar/MainNavbar";
import Footer from "../../components/landing/footer/Footer";
import {getFeaturedUniversities, getUniversities} from "../../redux/reducers/university";
import Select from "react-select";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {addTranscript} from "../../redux/reducers/transcripts";

const RequestTranscript = () => {
  const [loading, setLoading] = useState(false);
  const [Universities, setUniversities] = useState([]);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    fullName: "",
    matricule: "",
    graduationDate: "",
    email: "",
    university: "",
    transcriptType: "",
  });
  // const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(addTranscript(formData)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          toast.success(res.payload.message);
          window.location.href = "/alumni/request-transcript";
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.error("Error submitting form: ", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        await dispatch(getFeaturedUniversities()).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            return;
          } else {
            setUniversities(res.payload);
            return;
          }
        })
      } catch (error) {
        toast.error("An error occurred while fetching universities");
      } finally {
        setLoading(false);
      }
    };

    fetchUniversities();
  }, [dispatch]);

  console.log(Universities);

  return (
    <>
      <MainNavbar />
      <div className="bg-white">
        <div className="container py-4">
          <div className="card shadow-lg">
            <div className="card-body">
              <div className="py-2">
                <h2 className="text-primary">Request Your Transcript</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row py-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Full Name <span className="required">*</span>
                      </label>
                      <input
                          type="text"
                          name="fullName"
                          className="form-control"
                          placeholder="John Doe"
                          value={formData.fullName}
                          onChange={handleChange}
                          required={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="form-group">
                      <label className="form-label">
                        University <span className="text-danger">*</span>
                      </label>
                      <Select
                          options={Universities.map((university) => ({
                            value: university.id,
                            label: university.name,
                          }))}
                          required={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Student ID / Matricule{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                          type="text"
                          name="matricule"
                          className="form-control"
                          placeholder="12345678"
                          value={formData.matricule}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Graduation Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        name="graduationDate"
                        className="form-control"
                        value={formData.graduationDate}
                        onChange={handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="example@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Transcript Type <span className="text-danger">*</span>
                      </label>
                      <select
                        name="transcriptType"
                        id="type"
                        className="form-select form-control"
                        value={formData.transcriptType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="official">Official</option>
                        <option value="unofficial">Unofficial</option>
                      </select>
                    </div>
                  </div>

                </div>
                <div className="py-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </span>
                    ) : (
                      "Request Transcript"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RequestTranscript;
