// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.programSearch_searchSection__fZn8P {
    margin: 40px 0;
    text-align: center;
}

.programSearch_searchWidget__CECTW {
    /* border: 2px solid #483dad; */
    min-height: 150px;
    text-align: left;
    /* border-radius: 10px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.programSearch_searchWidget__CECTW h4 {
    display: block !important;
}

.programSearch_programList__qqT-O {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.programSearch_filter__Vk6Gc {
    width: 25%;
}

.programSearch_programs__CgfDa {
    width: 73%;
    margin-left: 2%;
    max-height: 100vh;
    overflow-y: scroll;
    border-radius: 10px;
}

.programSearch_applyBtn__DA8j9 a {
    color: #ccc;
}

.programSearch_noProgramsFound__QvKyo {
    font-size: 20px;
    text-align: center;
}

.programSearch_loader__LKp0v {
    width: 100%;
    text-align: center;
}

@media (max-width: 768px) {

    .programSearch_programList__qqT-O {
        display: block;
        padding: 0;
    }

    .programSearch_filter__Vk6Gc,
    .programSearch_programs__CgfDa {
        width: 100%;
        margin: 10px 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/programSearch/programSearch.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,+BAA+B;IAC/B,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB;;0BAEsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;;IAEI;QACI,cAAc;QACd,UAAU;IACd;;IAEA;;QAEI,WAAW;QACX,cAAc;IAClB;AACJ","sourcesContent":[".searchSection {\n    margin: 40px 0;\n    text-align: center;\n}\n\n.searchWidget {\n    /* border: 2px solid #483dad; */\n    min-height: 150px;\n    text-align: left;\n    /* border-radius: 10px; */\n    /* display: flex;\n    justify-content: center;\n    align-items: center; */\n}\n\n.searchWidget h4 {\n    display: block !important;\n}\n\n.programList {\n    margin-top: 20px;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.filter {\n    width: 25%;\n}\n\n.programs {\n    width: 73%;\n    margin-left: 2%;\n    max-height: 100vh;\n    overflow-y: scroll;\n    border-radius: 10px;\n}\n\n.applyBtn a {\n    color: #ccc;\n}\n\n.noProgramsFound {\n    font-size: 20px;\n    text-align: center;\n}\n\n.loader {\n    width: 100%;\n    text-align: center;\n}\n\n@media (max-width: 768px) {\n\n    .programList {\n        display: block;\n        padding: 0;\n    }\n\n    .filter,\n    .programs {\n        width: 100%;\n        margin: 10px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchSection": `programSearch_searchSection__fZn8P`,
	"searchWidget": `programSearch_searchWidget__CECTW`,
	"programList": `programSearch_programList__qqT-O`,
	"filter": `programSearch_filter__Vk6Gc`,
	"programs": `programSearch_programs__CgfDa`,
	"applyBtn": `programSearch_applyBtn__DA8j9`,
	"noProgramsFound": `programSearch_noProgramsFound__QvKyo`,
	"loader": `programSearch_loader__LKp0v`
};
export default ___CSS_LOADER_EXPORT___;
