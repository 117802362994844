import React from "react";
import styles from "./styles.module.css";
import { BsTwitterX } from "react-icons/bs";
import { FaChevronRight, FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <div className={[styles.footer, "container"].join(" ")}>
        <div className={styles.details}>
          <div className={styles.brand}>
            <h2>
              Campus<span>Camer</span>
            </h2>
            <p>**Empowering Your Educational Journey in Cameroon**</p>

            <div className={styles.icons}>
              <a href="#" className={styles.icon}>
                <FaFacebookF />
              </a>
              <a href="#" className={styles.icon}>
                <BsTwitterX />
              </a>
            </div>
          </div>
          <div className={styles.quickLink}>
            <h3>Quick Links</h3>
            <div>
              <a href="#why_campus_camer">Why choose campusCamer?</a>
            </div>
            <div>
              <a href="#programs_near_you">Amazing programs near you</a>
            </div>
            <div>
              <a href="#featured_programs">Featured institutions</a>
            </div>
            <div>
              <a href="#registration_steps">5 steps to studying in Cameroom</a>
            </div>
            <div>
              <a href="#registration_steps">How to Apply</a>
            </div>
          </div>
          <div className={styles.contacts}>
            <h3>Contact Us</h3>
            <div>
              <p>123 CampusCamer Lane, Douala, Cameroon</p>
            </div>
            <div>
              <p>support@campuscamer.com</p>
            </div>
          </div>
        </div>
        <div className={styles.logos}>
          <div>
            <h2>Powered By</h2>
            <img src="/logos/aeco.png" />
          </div>
          {/* <div>
            <img src="/logos/aecoeducation.png" />
          </div>
          <div>
            <img src="/logos/opuvia.png" />
          </div> */}
        </div>
      </div>

      <div className={styles.footerbottom}>
        <p>
          <a href="#">Terms Of Service</a>
          <span>
            <a href="#">Privacy Policy</a>
          </span>
          &copy; CampusCamer. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
