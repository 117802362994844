import React from "react";
import styles from "./styles.module.css";
import { Card } from "react-bootstrap";
import Carousel from "../../components/landing/teacherTestimonials/TeacherCarousel";
import FAQ from "../../components/landing/faq/FAQ";
import Footer from "../../components/landing/footer/Footer";
import MainNavbar from "../../components/navbar/MainNavbar";
import Accordion from "../../components/landing/accordion/Accordion";
import Profile from "../../components/teacher/profile/Profile";

const TeacherLanding = () => {
  const data = [
    {
      numbering: "1",
      title: "What information do I need to create a profile?",
      body: `
      To create a profile, you'll need to provide your name, email address, 
      password, and teaching credentials (e.g. degree, certification, etc.).
      `,
    },
    {
      numbering: "2",
      title: "What is the verification process?",
      body: `
      Our verification process involves reviewing your teaching 
      credentials and performing a background check to ensure your identity 
      and qualifications.`,
    },
    {
      numbering: "3",
      title: "How do schools and institutions find me?",
      body: `
      Schools and institutions can search for verified teachers on 
      our platform and filter by location, subject area, and other criteria.
      `,
    },
    {
      numbering: "4",
      title: "How long does verification take?",
      body: `
      Verification typically takes 3-5 business days, but may take longer in some cases.
      `,
    },
    {
      numbering: "5",
      title: "What if I'm not interested in a particular job offer?",
      body: `
      You can decline any job offer that doesn't align with your interests or goals.
      `,
    },
  ];

  return (
    <>
      <MainNavbar />
      <div className={styles.heroSection}>
        <div className={styles.overlay}>
          <div className="container">
            <h1 className={styles.header}>
              Shape Future Minds, Build Your Career
            </h1>
            <p className={styles.subHeader}>
              Create a profile, get certified, and connect with institutions
              looking for top educators.
            </p>
            <div className="d-flex justify-content-center flex-wrap pt-5">
              <div className="cta pe-4">
                <a href="/register" className="btn btn-light bg-white py-2 px-4 fs-4">
                  Create a profile
                </a>
              </div>
              <div className="cta py-sm-0 py-3">
                <a href="#verification-section" className="btn btn-primary py-2 px-4 fs-4">
                  Learn More About Verification
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-section py-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-7 ">
              <h2 className={styles.headerText}>
                Profile Creation and Management
              </h2>
              <p className={styles.textBasic}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
                facere fuga id voluptas harum libero iure adipisci doloribus
                obcaecati dolorum voluptate voluptatem veritatis nemo suscipit
                pariatur, officia nisi nam! Ad?
              </p>
              <div className="mt-5">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card shadow-lg">
                      <div className={styles.bgCard}>
                        <div className="icon mt-2 d-flex justify-content-center align-items-center">
                          <span className="flaticon-collaboration"></span>
                        </div>
                        <div className="card-body ">
                          <h3 className={styles.cardHeader}>Profile Picture</h3>
                          <p className={styles.textBasic}>
                            Use a recent, high-quality photo that presents you
                            in a professional light
                          </p>
                          <p className={styles.textBasic}>
                            Ensure the photo is well-lit, in focus, and has a
                            neutral background
                          </p>
                          <p className={styles.textBasic}>
                            Avoid using low-quality or outdated photos
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card shadow-lg">
                      <div className={styles.bgCard}>
                        <div className="icon mt-2 d-flex justify-content-center align-items-center">
                          <span className="flaticon-collaboration"></span>
                        </div>
                        <div className="card-body ">
                          <h3 className={styles.cardHeader}>
                            Your Qualifications
                          </h3>
                          <p className={styles.textBasic}>
                            List your relevant education, training, and
                            certifications.
                          </p>
                          <p className={styles.textBasic}>
                            Include any notable achievements, awards, or
                            recognition.
                          </p>
                          <p className={styles.textBasic}>
                            Keep this section concise and easy to scan.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card shadow-lg">
                      <div className={styles.bgCard}>
                        <div className="icon mt-2 d-flex justify-content-center align-items-center">
                          <span className="flaticon-collaboration"></span>
                        </div>
                        <div className="card-body ">
                          <h3 className={styles.cardHeader}>
                            {" "}
                            Your Experience
                          </h3>
                          <p className={styles.textBasic}>
                            Write a brief summary of your work experience,
                            focusing on achievements and impact
                          </p>
                          <p className={styles.textBasic}>
                            Highlight your skills, expertise, and
                            accomplishments in each role
                          </p>
                          <p className={styles.textBasic}>
                            Use bullet points or short paragraphs to make it
                            easy to read
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card shadow-lg">
                      <div className={styles.bgCard}>
                        <div className="icon mt-2 d-flex justify-content-center align-items-center">
                          <span className="flaticon-collaboration"></span>
                        </div>
                        <div className="card-body ">
                          <h3 className={styles.cardHeader}>
                            Relevant Skills and Endorsements
                          </h3>
                          <p className={styles.textBasic}>
                            List your relevant skills
                          </p>
                          <p className={styles.textBasic}>
                            Ask colleagues, mentors, or managers to endorse your
                            skills
                          </p>
                          <p className={styles.textBasic}>
                            Keep this section up-to-date and relevant to your
                            profession
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className={styles.rightSection}>
                <img
                  src="/images/landing/study_in_cameroon.jpg"
                  alt=""
                  className=" w-100 object-fit-cover pb-5"
                />
                <h2 className={styles.cardHeader}>Maintaining Your Profile</h2>
                <h6 className="py-3 fw-bold text-primary">
                  Regularly Update Your Profile Picture
                </h6>
                <ul>
                  <li className="pb-2 text-black">
                    Update your qualifications section when you complete new
                    courses, certifications, or training.
                  </li>
                  <li className="pb-2 text-black">
                    Remove outdated or irrelevant information.
                  </li>
                </ul>
                <h6 className="py-3 fw-bold text-primary">
                  Keep Your Qualifications Current
                </h6>
                <ul>
                  <li className="pb-2 text-black">
                    Update your qualifications section when you complete new
                    courses, certifications, or training.
                  </li>
                  <li className="pb-2 text-black">
                    Remove outdated or irrelevant information.
                  </li>
                </ul>
                <h6 className="py-3 fw-bold  text-primary">
                  Refresh Your Experience Summary
                </h6>
                <ul>
                  <li className="pb-2 text-black">
                    Update your experience summary every 6-12 months to reflect
                    new achievements and skills.
                  </li>
                  <li className="pb-2 text-black">
                    Remove outdated or irrelevant information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="verification-section py-5 bg-white">
        <div className={styles.bgVerification}>
          <div className={styles.verifyOverlay}>
            <div className="container">
              <h1 className={styles.header}>Get Verified and Certified</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className={styles.statistics} id="verification-section">
            <div className=" row justify-content-center">
              <div className=" col-md-4 text-center px-5 py-4 border-end">
                <h3 className={styles.headerText}>750+</h3>
                <p className=" text-black fs-4 fw-semibold">Teacher</p>
              </div>
              <div className=" col-md-4 text-center px-5 py-4 border-end">
                <h3 className={styles.headerText}>520+</h3>
                <p className=" text-black fs-4 fw-semibold">Verified Teacher</p>
              </div>
              <div className=" col-md-4 text-center px-5 py-4">
                <h3 className={styles.headerText}>800+</h3>
                <p className=" text-black fs-4 fw-semibold">Universities</p>
              </div>
            </div>
          </div>
          <div className=" text-center justify-content-center py-5" >
            <div className="px-md-5">
              <h3 className="pb-3 text-black">
                {" "}
                <span style={{ color: "#67126B" }}>Get Verified:</span> Unlock
                More Job Opportunities and Build Trust with Institutions
              </h3>
              <p className={styles.textBasic}>
                As a teacher, getting verified is an essential step in
                establishing credibility and showcasing your expertise. By
                becoming verified, you'll unlock a range of benefits that can
                take your career to the next level.
              </p>
            </div>
            <div className="py-3 px-md-5">
              <h4 className="text-black">More Job Opportunities</h4>
              <p className={styles.textBasic}>
                Verification opens doors to more job opportunities, both locally
                and globally. Institutions and organizations are more likely to
                hire verified teachers, as it demonstrates a higher level of
                competence and credibility. With verification, you'll stand out
                from the competition and increase your chances of landing your
                dream job.
              </p>
            </div>
            <div className="py-3 px-md-5">
              <h4 className="text-black">Trust from Institutions</h4>
              <p className={styles.textBasic}>
                Verification builds trust with institutions, which is critical
                in the education sector. By having a verified profile, you
                demonstrate a commitment to excellence and a willingness to
                undergo rigorous evaluation. This trust can lead to stronger
                relationships, better job prospects, and a stronger reputation
                in the industry.
              </p>
            </div>
            <div className="py-3 px-md-5">
              <h4 className="text-black">How to Get Verified</h4>
              <p className={styles.textBasic}>
                Ready to take the next step? Our certification process is
                designed to be straightforward and efficient. Simply follow
                these steps:
              </p>
              <div className=" justify-content-center mx-auto">
                <ol className=" ">
                  <li>
                    <p className={styles.textBasic}>
                      <span className="fw-bold pe-2">
                        Meet the Eligibility Criteria:
                      </span>
                      Ensure you meet the requirements for verification,
                      including education, experience, and training.
                    </p>
                  </li>
                  <li>
                    <p className={styles.textBasic}>
                      <span className="fw-bold pe-2">
                        Submit Your Application:
                      </span>
                      Provide the necessary documents and information to support
                      your application.
                    </p>
                  </li>
                  <li>
                    <p className={styles.textBasic}>
                      <span className="fw-bold pe-2">Undergo Evaluation:</span>
                      Our team will review your application and conduct a
                      thorough evaluation of your qualifications and experience.
                    </p>
                  </li>
                  <li>
                    <p className={styles.textBasic}>
                      <span className="fw-bold pe-2">Get Verified:</span>
                      Upon successful evaluation, you'll receive verification
                      and be able to showcase your credentials to the world.
                    </p>
                  </li>
                </ol>
              </div>
            </div> 
            <div className="py-3 px-md-5">
              <h4 className="text-black">
                Start Your Verification Journey Today!
              </h4>
              <p className={styles.textBasic}>
                Click the link below to begin the certification process and take
                the first step towards a more fulfilling and successful teaching
                career.
              </p>
              <div className="cta py-3">
                <button className="btn btn-primary py-2 px-4">
                  Start the Verification Process
                </button>
              </div>
              <p className={styles.textBasic}>
                By getting verified, you'll unlock new opportunities, build
                trust with institutions, and demonstrate your commitment to
                excellence in education. Don't wait – start your verification
                journey today!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.hiringSection}>
        <div className="container py-5 text-center">
          <h1 className={styles.headerText}>
            Be Visible to Institutions Hiring Teachers
          </h1>
          <p className=" text-black py-4">
            Our platform has been instrumental in connecting talented teachers
            with esteemed institutions worldwide. Here are some impressive
            statistics and testimonials that demonstrate the effectiveness of
            our verification process:
          </p>
          <div className={styles.hiringStatistics}>
            <div className=" row justify-content-center py-4">
              <div className=" col-md-4 text-center px-5 py-4 border-end">
                <h3 className={styles.headerText}>85%</h3>
                <p className=" text-black fs-5">
                  Of institutions prefer to hire verified teachers from our
                  platform.
                </p>
              </div>
              <div className=" col-md-4 text-center px-5 py-4 border-end">
                <h3 className={styles.headerText}>90%</h3>
                <p className=" text-black fs-5">
                  Of verified teachers receive job offers within 3 months of
                  completing their profile.
                </p>
              </div>
              <div className=" col-md-4 text-center px-5 py-4">
                <h3 className={styles.headerText}>75% </h3>
                <p className=" text-black fs-5">
                  Of institutions report a significant reduction in time-to-hire
                  after using our platform.
                </p>
              </div>
            </div>
          </div>
          <div className="testimonials mx-auto py-4">
            <div className="py-3">
              <h1 className={styles.headerText}>
                Success Stories from Our Teachers
              </h1>
            </div>
            <Carousel />
          </div>
        </div>
        <div className=" bg-white">
          <div className=" py-5 container">
            <h1 className="text-primary text-center">
              Frequently Asked Questions
            </h1>
            <div className="py-4">
              <div className={styles.accordions}>
                <Accordion data={data} />
              </div>
              {/* <FAQ /> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TeacherLanding;
