import React, {useEffect, useState} from "react";
import MainNavbar from "../../components/navbar/MainNavbar";
import Footer from "../../components/landing/footer/Footer";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {getTeacher} from "../../redux/reducers/user";
import toast from "react-hot-toast";
import {Link} from "react-router-dom";

const HireTeachers = () => {
  const dispatch = useDispatch();
  const [teachers, setTeacher] = useState([]);
  const [loading, setLoading] = useState(false);
  // const teachers = [
  //   {
  //     id: 1,
  //     firstName: "John",
  //     lastName: "Doe",
  //     age: 24,
  //     subject: "French",
  //     bio: "Passionate about teaching mathematics.",
  //     profileImage: "/images/profile/profile.png",
  //   },
  //   {
  //     id: 2,
  //     firstName: "Jane",
  //     lastName: "Smith",
  //     age: 35,
  //     subject: "English",
  //     bio: "Dedicated science teacher with 10 years of experience.",
  //     profileImage: "/images/profile/profile.png",
  //   },
  //   {
  //     id: 3,
  //     firstName: "John",
  //     lastName: "Doe",
  //     age: 32,
  //     subject: "Mathematics",
  //     bio: "Passionate about teaching mathematics.",
  //     profileImage: "/images/profile/profile.png",
  //   },
  //   {
  //     id: 4,
  //     firstName: "Jane",
  //     lastName: "Smith",
  //     age: 28,
  //     subject: "Economics",
  //     bio: "Dedicated science teacher with 10 years of experience.",
  //     profileImage: "/images/profile/profile.png",
  //   },
  //   {
  //     id: 5,
  //     firstName: "John",
  //     lastName: "Doe",
  //     age: 30,
  //     subject: "Law and Order",
  //     bio: "Passionate about teaching mathematics.",
  //     profileImage: "/images/profile/profile.png",
  //   },
  //   {
  //     id: 6,
  //     firstName: "Jane",
  //     lastName: "Smith",
  //     age: 37,
  //     subject: "Physics",
  //     bio: "Dedicated science teacher with 10 years of experience.",
  //     profileImage: "/images/profile/profile.png",
  //   },
  // ];


  useEffect(() => {
    const fetchTeachers = async () => {
      setLoading(true);
      try {
        const res = await dispatch(getTeacher());
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
        } else {
          setTeacher(res.payload);
        }
      } catch (error) {
        toast.error("An error occurred while fetching teachers");
      } finally {
        setLoading(false);
      }
    };

    fetchTeachers();
  }, []); // Use an empty array to run this effect only once

  console.log(teachers);

  return (
    <>
      <MainNavbar />
      <div className="bg-white">
        <div className="container py-5">
          <h1 className="text-center mb-4 text-primary">Verified Teachers</h1>
          <div className="text-center py-2">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
              voluptatem, repudiandae tempora libero omnis veniam ducimus, dolor
              debitis modi odio explicabo iure dolore ex nulla velit mollitia
              quasi necessitatibus totam.
            </p>
          </div>
          <div className="row">
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
            ) : (
                teachers.map((teacher) => (
                    <div className="col-md-4 mb-4" key={teacher._id}>
                      <div className="card shadow-lg rounded-5">
                        <div className="card-header border-bottom-0">
                          <img
                              src={(teacher.image && JSON.parse(teacher?.image)?.Location) || "/images/profile/profile.png"}
                              alt={`${teacher.fullName}`}
                              className="card-img rounded-circle mx-auto"
                              style={{ height: '150px', width: '150px'}}
                          />
                        </div>

                        <div className="card-body">
                          <h1 className="card-title text-black text-center">{`${teacher.fullName}`}</h1>
                          <div className="text-center">
                            <strong className="text-black pe-2">Age:</strong>
                            <span className="text-black-50"> {teacher.teacherDetails.age}</span>
                          </div>
                          <div className="text-center py-3">
                            <strong className="text-black pe-2">Subject:</strong>
                            <span className="text-black-50"> {teacher.teacherDetails.subject}</span>
                          </div>
                          <div className="text-center">
                            <div className="py-3">
                              {teacher.teacherDetails.isVerified && (
                                  <button className="badge badge-lg badge-outline-success bg-white me-2">
                                    <FaCheckCircle/> Verified
                                  </button>
                              )}

                              {teacher.teacherDetails.isCertified && (
                                  <button className="badge badge-lg badge-outline-primary bg-white">
                                    <FaCheckCircle/> Certified
                                  </button>
                              )}
                            </div>
                            <div className="py-3">
                              <Link
                                  to={`/institution/hire-teachers/details/${teacher._id}`} // Use Link for client-side navigation
                                  className="btn btn-primary rounded-5"
                              >
                                See more
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                ))
            )}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default HireTeachers;
