// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FOOTER BOTTOM */
.styles_footerbottom__AmmF4 {
    background-color: #303972;
    text-align: center;
    padding: 20px 0;
}

.styles_footerbottom__AmmF4 p a,
.styles_footerbottom__AmmF4 p {
    margin: 0;
    color: #F2F2F2;
    font-size: 14px;
}

.styles_footerbottom__AmmF4 span {
    text-align: center;
    border-right: 2px solid #F2F2F2;
    border-left: 2px solid #F2F2F2;
    padding: 0 10px;
    margin-right: 10px;
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/landing/footerBottom/styles.module.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,SAAS;IACT,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,8BAA8B;IAC9B,eAAe;IACf,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":["/* FOOTER BOTTOM */\n.footerbottom {\n    background-color: #303972;\n    text-align: center;\n    padding: 20px 0;\n}\n\n.footerbottom p a,\n.footerbottom p {\n    margin: 0;\n    color: #F2F2F2;\n    font-size: 14px;\n}\n\n.footerbottom span {\n    text-align: center;\n    border-right: 2px solid #F2F2F2;\n    border-left: 2px solid #F2F2F2;\n    padding: 0 10px;\n    margin-right: 10px;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerbottom": `styles_footerbottom__AmmF4`
};
export default ___CSS_LOADER_EXPORT___;
