// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_hero__8ukWl {
    padding: 40px 0;
}

.styles_header__Oyysb {
    text-align: center;
    font-size: 40px;
}

.styles_subHeader__itnpT {
    text-align: center;
    font-size: 20px;
    color: #000;
}

.styles_buttonWrapper__37xdb {
    text-align: center;
    padding: 30px 0;
}

.styles_buttonWrapper__37xdb a {
    background-color: #67126B;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
}

/* ABOUT US DETAILS */
.styles_aboutUs__1aIj5 {
    text-align: justify;
    background-color: #fff;
    padding: 40px 0;
}

.styles_aboutUs__1aIj5 p {
    font-size: 18px;
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/pages/aboutUs/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,eAAe;AACnB;;AAEA,qBAAqB;AACrB;IACI,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,WAAW;AACf","sourcesContent":[".hero {\n    padding: 40px 0;\n}\n\n.header {\n    text-align: center;\n    font-size: 40px;\n}\n\n.subHeader {\n    text-align: center;\n    font-size: 20px;\n    color: #000;\n}\n\n.buttonWrapper {\n    text-align: center;\n    padding: 30px 0;\n}\n\n.buttonWrapper a {\n    background-color: #67126B;\n    padding: 10px;\n    border-radius: 5px;\n    color: #fff;\n    font-size: 16px;\n}\n\n/* ABOUT US DETAILS */\n.aboutUs {\n    text-align: justify;\n    background-color: #fff;\n    padding: 40px 0;\n}\n\n.aboutUs p {\n    font-size: 18px;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `styles_hero__8ukWl`,
	"header": `styles_header__Oyysb`,
	"subHeader": `styles_subHeader__itnpT`,
	"buttonWrapper": `styles_buttonWrapper__37xdb`,
	"aboutUs": `styles_aboutUs__1aIj5`
};
export default ___CSS_LOADER_EXPORT___;
