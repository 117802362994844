import React, { useState } from "react";
import { Accordion, Card, Button, AccordionItem } from "react-bootstrap";
import "./styles.css";

const FAQ = () => {
  const [faqs, setFaqs] = useState([
    {
      id: 1,
      title: "Creating Profiles",
      questions: [
        {
          question: "What information do I need to create a profile?",
          answer:
            "To create a profile, you'll need to provide your name, email address, password, and teaching credentials (e.g. degree, certification, etc.).",
        },
        {
          question: "How long does it take to create a profile?",
          answer:
            "Creating a profile typically takes around 10-15 minutes, depending on how quickly you can gather the required information.",
        },
        {
          question: "Can I create a profile if I'm not a certified teacher?",
          answer:
            "Yes, you can create a profile even if you're not a certified teacher. However, you'll need to provide alternative documentation, such as a degree or relevant work experience, to demonstrate your teaching qualifications.",
        },
      ],
    },
    {
      id: 2,
      title: "Verification",
      questions: [
        {
          question: "What is the verification process?",
          answer:
            "Our verification process involves reviewing your teaching credentials and performing a background check to ensure your identity and qualifications.",
        },
        {
          question: "How long does verification take?",
          answer:
            "Verification typically takes 3-5 business days, but may take longer in some cases.",
        },
        {
          question: "What if I'm not verified?",
          answer:
            "If you're not verified, you'll receive an email explaining why and what steps you can take to resolve any issues.",
        },
      ],
    },
    {
      id: 3,
      title: "Hiring",
      questions: [
        {
          question: "How do schools and institutions find me?",
          answer:
            "Schools and institutions can search for verified teachers on our platform and filter by location, subject area, and other criteria.",
        },
        {
          question: "What if I'm not interested in a particular job offer?",
          answer:
            "You can decline any job offer that doesn't align with your interests or goals.",
        },
        {
          question: "How do I get paid?",
          answer:
            "We don't facilitate payment between teachers and schools. You'll need to discuss payment terms with the school or institution directly.",
        },
        {
          question: "Can I apply for multiple job openings at once?",
          answer:
            "Yes, you can apply for multiple job openings at once. However, be sure to tailor your application materials to each specific job opening to increase your chances of getting hired.",
        },
      ],
    },
  ]);
  return (
    <div className="card shadow-lg faq-card">
      <div className="card-body">
        <Accordion defaultActiveKey="0">
          {faqs.map((faq, index) => (
            <Accordion.Item eventKey={faq.id} key={faq.id}>
              <Accordion.Header className="faq-header">
                 <span className="fs-4">{faq.title}</span>
                <i className="fas fa-chevron-down"></i>
              </Accordion.Header>
              <Accordion.Body className="faq-body">
                {faq.questions.map((question, index) => (
                  <div key={question.question}>
                    <h5 className="question">{question.question}</h5>
                    <p className="answer">{question.answer}</p>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
