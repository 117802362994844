import React, { useState } from "react";
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap /Button';
// import Form from 'react-bootstrap/Form';

import { Modal } from "react-bootstrap";
// import Button from "react-bootstrap";
import { Form } from "react-bootstrap";

const Profile = () => {
  const [showModal, setShowModal] = useState(false);
  const [teacherProfile, setTeacherProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    bio: "",
    profileImage: null,
    workExperience: [],
    educationalQualifications: [],
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTeacherProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const handleProfileImageChange = (event) => {
    setTeacherProfile((prevProfile) => ({
      ...prevProfile,
      profileImage: event.target.files[0],
    }));
  };

  const handleAddWorkExperience = () => {
    setTeacherProfile((prevProfile) => ({
      ...prevProfile,
      workExperience: [
        ...prevProfile.workExperience,
        { school: "", startDate: "", endDate: "" },
      ],
    }));
  };

  const handleAddEducationalQualification = () => {
    setTeacherProfile((prevProfile) => ({
      ...prevProfile,
      educationalQualifications: [
        ...prevProfile.educationalQualifications,
        { certificate: "", school: "", year: "" },
      ],
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call API or perform other actions to create teacher profile
    console.log("Teacher profile created:", teacherProfile);
    handleCloseModal();
  };

  return (
    <>
      <button
        className="btn btn-light bg-white py-2 px-4 fs-4"
        type="button"
        onClick={handleShowModal}
      >
        Create a profile
      </button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Teacher Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="firstName">
              <Form.Label>First Name:</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={teacherProfile.firstName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name:</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={teacherProfile.lastName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={teacherProfile.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={teacherProfile.phoneNumber}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="bio">
              <Form.Label>Bio:</Form.Label>
              <Form.Control
                as="textarea"
                name="bio"
                value={teacherProfile.bio}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="profileImage">
              <Form.Label>Profile Image:</Form.Label>
              <Form.Control
                type="file"
                name="profileImage"
                onChange={handleProfileImageChange}
              />
              {teacherProfile.profileImage && (
                <img
                  src={URL.createObjectURL(teacherProfile.profileImage)}
                  alt="Profile Image"
                />
              )}
            </Form.Group>

            <h5>Work Experience</h5>
            {teacherProfile.workExperience.map((experience, index) => (
              <Form.Group key={index}>
                <Form.Label>School:</Form.Label>
                <Form.Control
                  type="text"
                  name={`workExperience[${index}].school`}
                  value={experience.school}
                  onChange={handleInputChange}
                />
                <Form.Label>Start Date:</Form.Label>
                <Form.Control
                  type="date"
                  name={`workExperience[${index}].startDate`}
                  value={experience.startDate}
                  onChange={handleInputChange}
                />
                <Form.Label>End Date:</Form.Label>
                <Form.Control
                  type="date"
                  name={`workExperience[${index}].endDate`}
                  value={experience.endDate}
                  onChange={handleInputChange}
                />
              </Form.Group>
            ))}
            <button
              onClick={handleAddWorkExperience}
              className="btn btn-primary"
            >
              Add Work Experience
            </button>

            <h5>Educational Qualifications</h5>
            {teacherProfile.educationalQualifications.map(
              (qualification, index) => (
                <Form.Group key={index}>
                  <Form.Label>Certificate:</Form.Label>
                  <Form.Control
                    type="text"
                    name={`educationalQualifications[${index}].certificate`}
                    value={qualification.certificate}
                    onChange={handleInputChange}
                  />
                  <Form.Label>School:</Form.Label>
                  <Form.Control
                    type="text"
                    name={`educationalQualifications[${index}].school`}
                    value={qualification.school}
                    onChange={handleInputChange}
                  />
                  <Form.Label>Year:</Form.Label>
                  <Form.Control
                    type="number"
                    name={`educationalQualifications[${index}].year`}
                    value={qualification.year}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              )
            )}
            <button
              className="btn btn-primary"
              onClick={handleAddEducationalQualification}
            >
              Add Educational Qualification
            </button>

            <button
              className="btn btn-primary"
              type="submit"
            >
                Create Profile
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* <button
        className="btn btn-light bg-white py-2 px-4 fs-4"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Create a profile
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title text-primary" id="exampleModalLabel">
                Create Your Teaching profile
              </h3>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form action="" method="post">
              <div class="modal-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="first_name" className=" form-label">First Name</label>
                            <input type="text" name="first_name"/>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Profile;
