import React, { useState } from "react";
import {addUser} from "../../redux/reducers/user";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";

const TeacherRegistration = () => {
  const [teacherProfile, setTeacherProfile] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    role: "TEACHER",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTeacherProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*]/.test(password);

    if (password.length < minLength) return "Password must be at least 8 characters long.";
    if (!hasUpperCase) return "Password must contain at least one uppercase letter.";
    if (!hasLowerCase) return "Password must contain at least one lowercase letter.";
    if (!hasNumbers) return "Password must contain at least one number.";
    if (!hasSpecialChars) return "Password must contain at least one special character.";
    
    return "";
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(""); 

    const passwordError = validatePassword(teacherProfile.password);
    if (passwordError) {
      setError(passwordError);
      setLoading(false);
      return; 
    }

    try {
      // console.log("Teacher profile created:", teacherProfile);
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      await dispatch(addUser(teacherProfile)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          toast.success(res.payload.message);
          window.location.href = "/login";
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.error("Error creating profile: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className=" py-3">
          <div className="form-group">
            <label htmlFor="fullName">Full Name <span className="required">*</span>:</label>
            <input
                type="text"
                className="form-control"
                name="fullName"
                placeholder='John Doe'
                value={teacherProfile.fullName}
                onChange={handleInputChange}
                required={true}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 py-3">
            <div className="form-group">
              <label htmlFor="emailAddress">Email <span className="required">*</span>:</label>
              <input
                  type="email"
                  className="form-control"
                  name="emailAddress"
                  placeholder='exampl@gmail.com'
                  value={teacherProfile.emailAddress}
                  onChange={handleInputChange}
                  required={true}
              />
            </div>
          </div>
          <div className="col-md-6 py-3">
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number <span className="required">*</span>:</label>
              <input
                  type="tel"
                  className="form-control"
                  name="phoneNumber"
                  placeholder='+237 677539681'
                  value={teacherProfile.phoneNumber}
                  onChange={handleInputChange}
                  required={true}
              />
            </div>
          </div>
        </div>

        <div className="py-3">
          <div className="form-group">
            <label htmlFor="password">Password <span className="required">*</span>:</label>
            <input
                type="password"
                className="form-control"
                name="password"
                placeholder='********'
                value={teacherProfile.password}
                onChange={handleInputChange}
                required={true}
            />
          </div>
          {error && <div className="text-danger">{error}</div>}
        </div>

        <button className="btn btn-primary w-100 my-3" type="submit" disabled={loading}>
          {loading ? (
              <>
              <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
              ></span>
                Loading...
              </>
          ) : (
              "Create Profile"
          )}
        </button>
        <div className="mt-3">
          Already have an account?{" "}
          <a href="/login" className="text-primary float-end mb-4">
            Login
          </a>
        </div>
      </form>
    </div>
  );
};

export default TeacherRegistration;