// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_dropdown__GOtGI {
    position: relative;
    display: inline-block;
    width: 100%;
}

.Dropdown_dropdownToggle__oDkJV {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    background-color: #fff;
    text-align: left;
    color: #1D1D1D !important;
}

.Dropdown_dropdownMenu__lL-ka {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    margin-top: 2px;
    z-index: 1000;
}

.Dropdown_searchInput__CEoDE {
    width: calc(100% - 20px);
    padding: 10px;
    border: none;
    border-bottom: 1px solid #e8e8e8;
}

.Dropdown_optionsList__x-3L3 {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.Dropdown_optionItem__OnZWE {
    padding: 10px;
}

.Dropdown_optionItem__OnZWE label {
    display: flex;
    align-items: center;
}

.Dropdown_optionItem__OnZWE input {
    margin-right: 10px;
}

@media (max-width: 768px) {
    .Dropdown_dropdown__GOtGI {
        display: block;
        width: 100%;
    }

    .Dropdown_dropdownToggle__oDkJV {
        display: block;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/selectDropdown/Dropdown.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,yBAAyB;IACzB,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,aAAa;IACb,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;QACd,WAAW;IACf;;IAEA;QACI,cAAc;QACd,WAAW;IACf;AACJ","sourcesContent":[".dropdown {\n    position: relative;\n    display: inline-block;\n    width: 100%;\n}\n\n.dropdownToggle {\n    width: 100%;\n    padding: 10px;\n    cursor: pointer;\n    border: 1px solid #e8e8e8;\n    border-radius: 10px;\n    background-color: #fff;\n    text-align: left;\n    color: #1D1D1D !important;\n}\n\n.dropdownMenu {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    background-color: #fff;\n    border: 1px solid #e8e8e8;\n    border-radius: 10px;\n    margin-top: 2px;\n    z-index: 1000;\n}\n\n.searchInput {\n    width: calc(100% - 20px);\n    padding: 10px;\n    border: none;\n    border-bottom: 1px solid #e8e8e8;\n}\n\n.optionsList {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    max-height: 200px;\n    overflow-y: auto;\n}\n\n.optionItem {\n    padding: 10px;\n}\n\n.optionItem label {\n    display: flex;\n    align-items: center;\n}\n\n.optionItem input {\n    margin-right: 10px;\n}\n\n@media (max-width: 768px) {\n    .dropdown {\n        display: block;\n        width: 100%;\n    }\n\n    .dropdownToggle {\n        display: block;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `Dropdown_dropdown__GOtGI`,
	"dropdownToggle": `Dropdown_dropdownToggle__oDkJV`,
	"dropdownMenu": `Dropdown_dropdownMenu__lL-ka`,
	"searchInput": `Dropdown_searchInput__CEoDE`,
	"optionsList": `Dropdown_optionsList__x-3L3`,
	"optionItem": `Dropdown_optionItem__OnZWE`
};
export default ___CSS_LOADER_EXPORT___;
