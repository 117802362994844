import React, { useEffect, useState } from "react";
import MainNavbar from "../../components/navbar/MainNavbar";
import Footer from "../../components/landing/footer/Footer";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getUser } from "../../redux/reducers/user";

const TeacherDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        const res = await dispatch(getUser(id));
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
        } else {
          // toast.success(res.payload.message);
          setTeacher(res.payload);
        }
      } catch (error) {
        toast.error("An error occurred while fetching teacher details.");
      } finally {
        setLoading(false);
      }
    };

    fetchTeacher();
  }, [dispatch, id]);

  const { fullName, teacherDetails = {}, image } = teacher;
  const { subject, shortDescription, longDescription } = teacherDetails;

  return (
    <>
      <MainNavbar />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="bg-white">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-7">
                <p className="text-success text-bg-light w-50 text-center fs-4">
                  More About Our Teacher
                </p>
                <h1 className="text-primary" style={{ fontSize: "60px" }}>
                  {fullName}
                </h1>
                <p className="text-dark fs-4">{subject}</p>
                <p className="text-dark fs-4 py-2">{shortDescription}</p>
                <hr />
                <h1 className="text-primary" style={{ fontSize: "50px" }}>
                  Here are some details:
                </h1>
                <p className="text-dark">{longDescription}</p>
              </div>
              <div className="col-md-5 py-3">
                <img
                  src={
                    (image && JSON.parse(image)?.Location) ||
                    "https://via.placeholder.com/150"
                  }
                  alt={fullName}
                  className="object-fit-cover w-100"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default TeacherDetails;
