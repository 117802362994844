import React, { useContext } from "react";
import Loader from "../Loader/Loader";
import { formatMoney } from "../../utils/formatMoney";
import { AuthContext } from "../../context/AuthContext";

const Recent = ({ programs, students, admins }) => {
  const { role } = useContext(AuthContext);

  return (
    <div>
      <div
        className="wallet-bar wow fadeInRight dlab-scroll active"
        id="wallet-bar"
        data-wow-delay="0.7s"
      >
        <div className="row">
          <div className="col-xl-12">
            <div className="card bg-transparent mb-1">
              <div className="card-header border-0 px-3">
                <div>
                  <h2 className="heading mb-0">New Programs</h2>
                  <span>
                    You have{" "}
                    <span className="font-w600">{programs?.length}</span>{" "}
                    Programs
                  </span>
                </div>

                {/* <div>
                  <a
                    href="#"
                    className="add icon-box bg-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.188 13.412V8.512H0.428V5.348H5.188V0.531999H8.352V5.348H13.14V8.512H8.352V13.412H5.188Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div> */}
              </div>
              <div
                className="card-body height450 dlab-scroll loadmore-content recent-activity-wrapper p-3 pt-2"
                id="RecentActivityContent"
              >
                {/* Student List */}
                {programs.length > 0 ? (
                  programs?.slice(0, 10).map((program, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center student"
                    >
                      <span className="dz-media">
                        <img
                          src={"./images/trans/1.jpg"}
                          alt=""
                          width="50"
                          className="avatar"
                        />
                      </span>
                      <div className="user-info">
                        <h6 className="name">
                          <a href={`/program-details?=${program._id}`}>
                            {program.name}
                          </a>
                        </h6>
                        <span className="fs-14 font-w400 text-wrap">
                          {program.academicYear} /{" "}
                          {formatMoney(program.tuitionFee)} XAF
                        </span>
                      </div>
                      {/* <div className="icon-box st-box ms-auto">
                        <a
                          target="_blank"
                          href={`mailto:${student.emailAddress}`}
                        >
                          <svg
                            width="22"
                            height="16"
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 0.000114441H3C2.20435 0.000114441 1.44129 0.316185 0.878679 0.878794C0.31607 1.4414 0 2.20446 0 3.00011V13.0001C0 13.7958 0.31607 14.5588 0.878679 15.1214C1.44129 15.684 2.20435 16.0001 3 16.0001H19C19.7956 16.0001 20.5587 15.684 21.1213 15.1214C21.6839 14.5588 22 13.7958 22 13.0001V3.00011C22 2.20446 21.6839 1.4414 21.1213 0.878794C20.5587 0.316185 19.7956 0.000114441 19 0.000114441V0.000114441ZM20 12.7501L15.1 8.35011L20 4.92011V12.7501ZM2 4.92011L6.9 8.35011L2 12.7501V4.92011ZM8.58 9.53011L10.43 10.8201C10.5974 10.9362 10.7963 10.9985 11 10.9985C11.2037 10.9985 11.4026 10.9362 11.57 10.8201L13.42 9.53011L18.42 14.0001H3.6L8.58 9.53011ZM3 2.00011H19C19.1857 2.0016 19.3673 2.05478 19.5245 2.15369C19.6817 2.2526 19.8083 2.39333 19.89 2.56011L11 8.78011L2.11 2.56011C2.19171 2.39333 2.31826 2.2526 2.47545 2.15369C2.63265 2.05478 2.81428 2.0016 3 2.00011V2.00011Z"
                              fill="#A098AE"
                            />
                          </svg>
                        </a>
                      </div> */}
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Loader />
                  </div>
                )}
                {/* End of Student List */}
              </div>
              {/* <div className="card-footer text-center border-0 pt-0 px-3 pb-0"> */}
              <a
                href="/students"
                className="btn btn-block btn-primary light btn-rounded dlab-load-more"
              >
                View More
              </a>
              {/* </div> */}
            </div>
          </div>
          {role !== "STUDENT" && (
            <div className="col-xl-12">
              <div className="card bg-transparent mb-1">
                <div className="card-header border-0 px-3">
                  <div>
                    <h2 className="heading mb-0">Recent Students</h2>
                    <span>
                      You have{" "}
                      <span className="font-w600">{students?.length}</span>{" "}
                      Students
                    </span>
                  </div>

                  {/* <div>
                  <a
                    href="#"
                    className="add icon-box bg-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.188 13.412V8.512H0.428V5.348H5.188V0.531999H8.352V5.348H13.14V8.512H8.352V13.412H5.188Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div> */}
                </div>
                <div
                  className="card-body height450 dlab-scroll loadmore-content recent-activity-wrapper p-3 pt-2"
                  id="RecentActivityContent"
                >
                  {/* Student List */}
                  {students.length > 0 ? (
                    students?.map((student, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center student"
                      >
                        <span className="dz-media">
                          <img
                            src={
                              (student.image &&
                                JSON.parse(student?.image)?.Location) ||
                              "./images/trans/1.jpg"
                            }
                            alt=""
                            width="50"
                            className="avatar"
                          />
                        </span>
                        <div className="user-info">
                          <h6 className="name">
                            <a href={`/students-details?=${student._id}`}>
                              {student.fullName}
                            </a>
                          </h6>
                          <span className="fs-14 font-w400 text-wrap">
                            {student.emailAddress}
                          </span>
                        </div>
                        {/* <div className="icon-box st-box ms-auto">
                        <a
                          target="_blank"
                          href={`mailto:${student.emailAddress}`}
                        >
                          <svg
                            width="22"
                            height="16"
                            viewBox="0 0 22 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19 0.000114441H3C2.20435 0.000114441 1.44129 0.316185 0.878679 0.878794C0.31607 1.4414 0 2.20446 0 3.00011V13.0001C0 13.7958 0.31607 14.5588 0.878679 15.1214C1.44129 15.684 2.20435 16.0001 3 16.0001H19C19.7956 16.0001 20.5587 15.684 21.1213 15.1214C21.6839 14.5588 22 13.7958 22 13.0001V3.00011C22 2.20446 21.6839 1.4414 21.1213 0.878794C20.5587 0.316185 19.7956 0.000114441 19 0.000114441V0.000114441ZM20 12.7501L15.1 8.35011L20 4.92011V12.7501ZM2 4.92011L6.9 8.35011L2 12.7501V4.92011ZM8.58 9.53011L10.43 10.8201C10.5974 10.9362 10.7963 10.9985 11 10.9985C11.2037 10.9985 11.4026 10.9362 11.57 10.8201L13.42 9.53011L18.42 14.0001H3.6L8.58 9.53011ZM3 2.00011H19C19.1857 2.0016 19.3673 2.05478 19.5245 2.15369C19.6817 2.2526 19.8083 2.39333 19.89 2.56011L11 8.78011L2.11 2.56011C2.19171 2.39333 2.31826 2.2526 2.47545 2.15369C2.63265 2.05478 2.81428 2.0016 3 2.00011V2.00011Z"
                              fill="#A098AE"
                            />
                          </svg>
                        </a>
                      </div> */}
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <Loader />
                    </div>
                  )}
                  {/* End of Student List */}
                </div>
                {/* <div className="card-footer text-center border-0 pt-0 px-3 pb-0"> */}
                <a
                  href="/students"
                  className="btn btn-block btn-primary light btn-rounded dlab-load-more"
                >
                  View More
                </a>
                {/* </div> */}
              </div>
            </div>
          )}
          {role !== "STUDENT" &&
            role !== "TEACHER" &&
            role !== "COUNSELLOR" &&
            role !== "ADMISSION_OFFICER" && (
              <div className="col-xl-12">
                <div className="card massage bg-transparent mb-0">
                  <div className="card-header border-0 px-3 py-0">
                    <div>
                      <h2 className="heading mb-0">Recent Admins</h2>
                      <span>
                        You have{" "}
                        <span className="font-w600">{admins?.length}</span>{" "}
                        Admins
                      </span>
                    </div>
                  </div>
                  <div
                    className="card-body height450 dlab-scroll p-0 px-3"
                    id="messageContent"
                  >
                    {/* Message List */}
                    {admins.length > 0 ? (
                      admins.map((admin, index) => (
                        <div
                          key={index}
                          className="d-flex student border-bottom"
                        >
                          <div className="d-flex align-items-center">
                            <span className="dz-media">
                              <img
                                src={
                                  (admin.image &&
                                    JSON.parse(admin?.image)?.Location) ||
                                  "./images/trans/1.jpg"
                                }
                                alt=""
                                className="avatar"
                              />
                            </span>
                            <div className="user-info">
                              <h6 className="name">
                                <a href={`/admin-details?=${admin._id}`}>
                                  {admin.fullName}
                                </a>
                              </h6>
                              <span className="fs-14 font-w400 text-wrap">
                                {admin.emailAddress}
                              </span>
                            </div>
                          </div>
                          {/* <div className="indox">
                        <span className="fs-14 font-w400 text-wrap">
                          {admin.phoneNumber}
                        </span>
                      </div> */}
                        </div>
                      ))
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Loader />
                      </div>
                    )}
                    {/* End of Message List */}
                  </div>
                  <div className="card-footer text-center border-0 px-3 pb-0">
                    <a
                      href="/admins"
                      className="btn btn-block btn-primary light btn-rounded "
                    >
                      View More
                    </a>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <div className="wallet-bar-close"></div>
    </div>
  );
};

export default Recent;
