import React, { useState, useContext } from "react";
import styles from "./Navbar.module.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import ChangeLanguage from "../../i18next/changeLanguage/ChangeLanguage";

import { useTranslation } from "react-i18next";

const MainNavbar = () => {
  const { t } = useTranslation();

  const { authenticated } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <nav className={`${styles.navbar} sticky-top`}>
      <div className={`container ${styles.navbar_container}`}>
        <div className={styles.logo}>
          <a href="/">
            <img src="/logos/logo_color.png" alt="Logo" />
          </a>
        </div>
        {/* <div className={styles.links}>
          <a href="#home" className={styles.link}>
            Home
          </a>
          <a href="/about-us" className={styles.link}>
            About Us
          </a>
          <a href="#services" className={styles.link}>
            Services
          </a>
          <a href="/contact-us" className={styles.link}>
            Contact Us
          </a>
          <a href="/search-program" className={styles.link}>
            Search Programs
          </a>
          <a>{t("greeting")}</a>
        </div> */}
        <div className={styles.buttons}>
          {/* <ChangeLanguage /> */}
          {authenticated ? (
            <a href="/dashboard" className={styles.login}>
              Account
            </a>
          ) : (
            <>
              <a href="/login" className={styles.login}>
                Login
              </a>
              <a href="/register" className={styles.signup}>
                Sign Up
              </a>
            </>
          )}
        </div>
        <div className={styles.menuIcon}>
          <ChangeLanguage />
          <div onClick={toggleSidebar}>
            {isSidebarOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default MainNavbar;
