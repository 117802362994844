import React from "react";
import MainNavbar from "../../components/navbar/MainNavbar";
import Footer from "../../components/landing/footer/Footer";
import { Carousel } from "react-bootstrap";
import "./alumni.css";

const AlumniLanding = () => {
  const testimonials = [
    {
      quote:
        "I needed a transcript urgently for a job application, and I was able to request it through the platform and receive it within a few days. It was incredibly convenient and saved me a lot of time.",
      author: "John Lee",
      title: "Alumni",
      image: "/images/landing/programs_near.png",
    },
    {
      quote:
        "I used the platform to reconnect with classmates I hadn't seen in years. We've been meeting up regularly and have even started a business together.",
      author: "Emily Chen",
      title: "ALumni",
      image: "/images/landing/programs_near.png",
    },
    {
      quote:
        "Thanks to the alumni network, I was able to land a new job in my field. I connected with a former classmate who referred me to the company.",
      author: "Michael Brown",
      title: "Alumni",
      image: "/images/landing/programs_near.png",
    },
    {
      quote:
        "I've been taking advantage of the online courses offered through the platform. It's a great way to stay updated on the latest trends in my industry.",
      author: "Michael Brown",
      title: "Alumni",
      image: "/images/landing/programs_near.png",
    },
    {
      quote:
        "I've been volunteering with a local organization through the platform. It's a great way to give back to the community and stay connected to my alma mater.",
      author: "Michael Brown",
      title: "Alumni",
      image: "/images/landing/programs_near.png",
    },
  ];
  return (
    <>
      <MainNavbar />
      <div className="heroSection">
        <div className="overlay">
          <div className="container">
            <div className="">
              <div className="col-md-6">
                <div className="card shadow-lg z-3">
                  <div className="card-body">
                    <h1 className=" text-primary header-text text-sm-start text-center">
                      Stay Connected, Get Your Transcript Hassle-Free
                    </h1>
                    <p className="text-center text-md-start text-black">
                      <span className="text-secondary fs-5 fw-semibold">
                        Request Your Official Transcript with Ease:
                      </span>{" "}
                      <br />
                      Click the "Transcript Request" button. Fill out the
                      required information, including your full name, student
                      ID, graduation year, and recipient's address. Choose the
                      desired transcript type official or unofficial and pay any
                      applicable fees. Once submitted, you can track the status
                      of your request and download or receive your transcript
                      when it's ready. For any questions or assistance, please
                      contact us.
                    </p>
                    <div className="py-2">
                      <a href="/alumni/request-transcript" className="btn btn-primary">
                        Request Your Transcript
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="transcript-section bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-7 py-5">
              <div className="text-center">
                <h1 className="text-primary">How to Request Your Transcript</h1>
                <p className="py-3 text-black">
                  comprehensive instructions on how alumni can apply for
                  transcripts through the platform, I'll need more information
                  about the specific features and functionalities of your
                  platform. However, here's a general outline that you can adapt
                  based on your platform's requirements:
                </p>
              </div>
              <div className="py-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className=" col-2 bg-secondary d-none d-sm-block rounded-circle step text-center align-items-center pt-2">
                        <span className="text-white fs-3">1</span>
                      </div>
                      <div className=" col-sm-10 ps-2 text-center text-sm-start">
                        <h3 className="text-primary">Visit the Website</h3>
                        <p className="text-black">
                          Visit <a href="#"className="text-secondary">CampusCamer</a> on the alumni
                          section, click on the "Request Transcript" button.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className=" col-2 bg-secondary d-none d-sm-block rounded-circle step text-center align-items-center pt-2">
                        <span className="text-white fs-3">2</span>
                      </div>
                      <div className="col-sm-10 ps-2 text-center text-sm-start">
                        <h3 className="text-primary">Fill Out the Request Form</h3>
                        <p className="text-black">
                          Enter your personal information, including your name,
                          student ID, graduation year,and contact details and
                          Select the desired transcript type.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className=" col-2 bg-secondary d-none d-sm-block rounded-circle step text-center align-items-center pt-2">
                        <span className="text-white fs-3">3</span>
                      </div>
                      <div className="col-sm-10 ps-2 text-center text-sm-start">
                        <h3 className="text-primary">Pay Fees:</h3>
                        <p className="text-black">
                          pay platform fee associated with the transcript
                          request, follow the instructions to make the payment
                          once payment is complete procced to the next step to
                          submit request.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className=" col-2 bg-secondary d-none d-sm-block rounded-circle step text-center align-items-center pt-2">
                        <span className="text-white fs-3">4</span>
                      </div>
                      <div className="col-sm-10 ps-2 text-center text-sm-start">
                        <h3 className="text-primary">Review and Submit</h3>
                        <p className=" text-black">
                          Carefully review the information you've entered to
                          ensure accuracy.
                        </p>
                        <p className=" text-black">
                          Click the "Submit" button to finalize your request.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className=" col-2 bg-secondary d-none d-sm-block rounded-circle step text-center align-items-center pt-2">
                        <span className="text-white fs-3">5</span>
                      </div>
                      <div className="col-sm-10 ps-2 text-center text-sm-start">
                        <h3 className="text-primary"> Track Your Request:</h3>
                        <p className=" text-black">
                          You can track the status of your transcript request by
                          visiting the "Transcript Requests" section of your
                          account.
                        </p>
                        <p className=" text-black">
                          The status will be updated as your request is
                          processed.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className=" col-2 bg-secondary d-none d-sm-block rounded-circle step text-center align-items-center pt-2">
                        <span className="text-white fs-3">6</span>
                      </div>
                      <div className="col-sm-10 ps-2 text-center text-sm-start">
                        <h3 className="text-primary"> Receive Your Transcript:</h3>
                        <p className=" text-black">
                          TOnce your transcript is ready, you can download it as
                          a PDF file from your account or have it mailed to the
                          recipient's address.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <img
                src="/images/alumni1.jpg"
                alt=""
                className="w-100 h-100 object-fit-cover"
                // style={{ objectFit: 'cover'}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="institude-connect">
        <div className="overlay">
          <div className="container">
            <div className="">
              <div className="px-md-5">
                <div className="text-center">
                  <h1 className="text-white">Reconnect with Your Alma Mater</h1>
                  <p className="text-white  fs-4">
                    Staying connected to your alma mater offers numerous
                    benefits for alumni. By maintaining your engagement, you can
                    expand your professional network, access exclusive
                    opportunities tailored to your needs, contribute to the
                    university's success through volunteering and giving back,
                    stay informed about campus news and events, and continue
                    your lifelong learning journey.
                  </p>
                  <p className="text-white fs-4">
                    To stay connected, consider joining the alumni association,
                    following the university on social media, attending alumni
                    events and reunions, volunteering for campus initiatives,
                    and donating to support student scholarships and programs.
                    By actively participating in these activities, you'll not
                    only enrich your own life but also contribute to the growth
                    and development of your alma mater.
                  </p>
                  <a
                    href="#"
                    className="btn btn-light mx-auto py-3 px-4 fs-4 fs-sm-3 my-4"
                  >
                    Connect with your institution
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="alumni-benefits bg-white">
        <div className="container py-5 text-center">
          <h1 className="text-primary">Why Stay Connected</h1>
          <span className="text-secondary fs-5 fw-bold">
            Unlock the Power of Your Alumni Network
          </span>
          <p className="py-3 fs-5 text-black">
            As a valued member of the University alumni community, you have
            access to a wealth of exclusive benefits that can enhance your
            personal and professional life.
          </p>
          <div className="row">
            <div className="col-md-6 text-start">
              <h3 className="text-primary">Networking Opportunities:</h3>
              <dl>
                <dt className="text-primary fs-4">Connect with fellow alumni: </dt>
                <dd className="fs-5 text-black">
                  - Expand your professional network by connecting with
                  like-minded individuals who share your experiences and
                  aspirations.
                </dd>
                <dt className="text-primary fs-4">Attend alumni events: </dt>
                <dd className="fs-5 text-black">
                  - Participate in networking events, reunions, and conferences
                  to meet new people and strengthen existing relationships.
                </dd>
                <dt className="text-primary fs-4">Leverage the alumni directory: </dt>
                <dd className="fs-5 text-black">
                  - Access a comprehensive directory of alumni to find potential
                  mentors, collaborators, or business partners.
                </dd>
              </dl>
            </div>
            <div className="col-md-6 text-start">
              <h3 className="text-primary">Career Advancement:</h3>
              <dl>
                <dt className="text-primary fs-4">Job postings and career advice: </dt>
                <dd className="fs-5 text-black">
                  - Stay informed about job opportunities and receive
                  personalized career guidance from alumni mentors.
                </dd>
                <dt className="text-primary fs-4">
                  Resume reviews and interview preparation:
                </dt>
                <dd className="fs-5 text-black">
                  - Get expert feedback on your resume and interview skills to
                  increase your chances of success.
                </dd>
                <dt className="text-primary fs-4">Alumni mentorship programs: </dt>
                <dd className="fs-5 text-black">
                  - Connect with successful alumni who can offer advice,
                  support, and guidance.
                </dd>
              </dl>
            </div>
            <div className="col-md-6 text-start">
              <h3 className="text-primary">Exclusive Events and Discounts:</h3>
              <dl>
                <dt className="text-primary fs-4">Attend alumni-only events: </dt>
                <dd className="fs-5 text-black">
                  - Enjoy exclusive access to social gatherings, lectures, and
                  workshops.
                </dd>
                <dt className="text-primary fs-4">
                  Receive discounts on products and services:{" "}
                </dt>
                <dd className="fs-5 text-black">
                  - Take advantage of special offers and discounts from
                  alumni-owned businesses and partners.
                </dd>
              </dl>
            </div>
            <div className="col-md-6 text-start">
              <p className="text-black fs-4">
                By actively participating in your alumni network, you can unlock
                a world of opportunities and benefits that will enrich your
                personal and professional life.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials">
        <div className="container">
          <div className="mx-auto justify-content-center py-5">
            <h1 className="text-center">Hear from Our Alumni</h1>
            <Carousel>
              {testimonials.map((testimonial, index) => (
                <Carousel.Item key={index}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={testimonial.image}
                      alt={testimonial.author}
                      className="testimonial-image"
                    />
                  </div>

                  <div className="testimonial">
                    <div className="testimonial-content">
                      <p>{testimonial.quote}</p>
                    </div>
                    <div className="testimonial-author">
                      <strong>{testimonial.author}</strong>
                      <span>{testimonial.title}</span>
                    </div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AlumniLanding;
