import axios from "axios";
import {createAsyncThunk} from "@reduxjs/toolkit";

const url = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_VERSION}/transcripts`;
const token = localStorage.getItem("campus-camer-user");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`

export const getTranscripts = createAsyncThunk("transcripts/getTranscripts", async (thunkAPI) => {
    try {
        const response = await axios.get(`${url}/`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getTranscript = createAsyncThunk("transcripts/getTranscript", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/details/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addTranscript = createAsyncThunk("transcripts/addTranscript", async (data, thunkAPI) => {
    try {
        const response = await axios.post(`${url}/create`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateTranscript = createAsyncThunk("transcripts/updateTranscript", async (data, thunkAPI) => {
    try {
        const response = await axios.put(`${url}/update/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const deleteTranscript = createAsyncThunk("transcripts/deleteTranscript", async (data, thunkAPI) => {
    try {
        const response = await axios.delete(`${url}/delete/${data.id}`, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
