import React, { useState } from "react";
import StudentRegistration from "../../components/auth/StudentRegistration";
import TeacherRegistration from "../../components/auth/TeacherRegistration";

const Signup = () => {
  const [userType, setUserType] = useState("");

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  return (
    <div>
      <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="login-aside text-center  d-flex flex-column flex-row-auto">
          <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
            <div className="text-center mb-lg-4 mb-2 pt-5 logo">
              <img
                src="/logos/logo_white.png"
                alt=""
                style={{ width: "50%" }}
              />
            </div>
            <h3 className="mb-2 text-white">Welcome To Campus Camer!</h3>
            <p className="mb-4">
              Discover A Wide Range Of Study <br /> Programs Available In
              Cameroon.
            </p>
          </div>
          <div
            className="aside-image position-relative"
            style={{ backgroundImage: "url(images/background/pic-2.png)" }}
          >
            {/* <img
            className="img1 move-1"
            src="images/background/pic3.png"
            alt=""
          />
          <img
            className="img2 move-2"
            src="images/background/pic4.png"
            alt=""
          />
          <img
            className="img3 move-3"
            src="images/background/pic5.png"
            alt=""
          /> */}
          </div>
        </div>
        <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
          <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="authincation-content style-2">
              <div className="text-center">
                <h3 className="text-center mb-2 text-black">
                  Create An Account
                </h3>
                <span>Welcome to Campus Camer</span>
              </div>
              {/* <label>Select User Type</label> */}
              {/* <select
                value={userType}
                onChange={handleUserTypeChange}
                className="form-control"
              >
                <option value="" disabled>
                  select type
                </option>
                <option value="student">Student</option>
                <option value="teacher">Teacher</option>
              </select> */}

              <StudentRegistration />
              {/* {userType === "student" && <StudentRegistration />}
              {userType === "teacher" && <TeacherRegistration />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
