import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import Header from "../components/header/Header";
// import Modal from "../components/modal/Modal";
import { AuthContext } from "../context/AuthContext";

const DashboardLayout = ({ children }) => {
  const { userId } = useContext(AuthContext);
  const token = localStorage.getItem("campus-camer-user");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  }, [token, navigate]);

  const year = new Date().getFullYear();

  return (
    <>
      <div id="preloader">
        <div className="loader">
          <div className="dots">
            <div className="dot mainDot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        </div>
      </div>
      <div id="main-wrapper" className="wallet-open active">
        <div className="nav-header">
          <a href="/dashboard" className="brand-logo">
            <img src="/logos/logo_white.png" />
          </a>

          <div className="nav-control">
            <div className="hamburger">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="22"
                  y="11"
                  width="4"
                  height="4"
                  rx="2"
                  fill="#2A353A"
                />
                <rect x="11" width="4" height="4" rx="2" fill="#2A353A" />
                <rect x="22" width="4" height="4" rx="2" fill="#2A353A" />
                <rect
                  x="11"
                  y="11"
                  width="4"
                  height="4"
                  rx="2"
                  fill="#2A353A"
                />
                <rect
                  x="11"
                  y="22"
                  width="4"
                  height="4"
                  rx="2"
                  fill="#2A353A"
                />
                <rect width="4" height="4" rx="2" fill="#2A353A" />
                <rect y="11" width="4" height="4" rx="2" fill="#2A353A" />
                <rect
                  x="22"
                  y="22"
                  width="4"
                  height="4"
                  rx="2"
                  fill="#2A353A"
                />
                <rect y="22" width="4" height="4" rx="2" fill="#2A353A" />
              </svg>
            </div>
          </div>
        </div>

        <Header userId={userId} />

        <Sidebar />

        <div className="content-body mh-auto">
          <div className="container-fluid">{children}</div>
        </div>

        <div className="footer footer-outer">
          <div className="copyright">
            <p>
              Powered by{" "}
              <a href="https://aecoedu.com/" target="_blank">
                AECO EDUCATION
              </a>{" "}
              &amp; Copyright © {year}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
