import React from "react";
import styles from "./styles.module.css";
import MainNavbar from "../../components/navbar/MainNavbar";
import FooterBottom from "../../components/landing/footerBottom/FooterBottom";

const aboutUs = () => {
  return (
    <div>
      <MainNavbar />

      <div className={styles.hero}>
        <div className="container">
          <h1 className={styles.header}>About Us</h1>
          <p className={styles.subHeader}>
            We're a leading provider of education and career development in
            Cameroon, helping students and professionals find their passions,
            opportunities, and fulfillment.
          </p>
          <div className={styles.buttonWrapper}>
            <a href="/contact-us" className={styles.button}>
              Connect With Us
            </a>
          </div>
        </div>
      </div>

      <div className={styles.aboutUs}>
        <div className="container">
          <div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reiciendis tempora commodi laudantium iusto repellat vel debitis
              enim facere ut neque. Beatae libero atque rerum? Quasi dignissimos
              maiores esse aut nemo. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Reiciendis tempora commodi laudantium iusto
              repellat vel debitis enim facere ut neque. Beatae libero atque
              rerum? Quasi dignissimos maiores esse aut nemo.Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Reiciendis tempora commodi
              laudantium iusto repellat vel debitis enim facere ut neque. Beatae
              libero atque rerum? Quasi dignissimos maiores esse aut nemo. Lorem
              ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis
              tempora commodi laudantium iusto repellat vel debitis enim facere
              ut neque. Beatae libero atque rerum? Quasi dignissimos maiores
              esse aut nemo.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reiciendis tempora commodi laudantium iusto repellat vel debitis
              enim facere ut neque. Beatae libero atque rerum? Quasi dignissimos
              maiores esse aut nemo.
            </p>
          </div>
        </div>
      </div>

      <FooterBottom />
    </div>
  );
};

export default aboutUs;
